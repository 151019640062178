import { Menu } from './menu.model';

function MenuRoles() {
   var admin = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

    new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
    new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
    new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    new Menu (19, 'Request loan', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    new Menu (25, 'Expenditure', null, null, 'shop_two', null, true, 0),
    new Menu (26, 'View or create', '/expenditure/view', null, 'shopping_cart', null, false, 25),


    new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),


    new Menu (35, 'Messages', null, null, 'devices', null, true, 0),
    new Menu (36, 'Create or Send', '/sms/sms', null, 'mobile_screen_share', null, false, 35),
    new Menu (37, 'Balance', '/sms/balance', null, 'phonelink_ring', null, false, 35),


    new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),
   ]

   var member = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    // new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    // new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    // new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

    // new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    // new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    // new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
    // new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
    // new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    new Menu (19, 'Request loan and Info', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    // new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    // new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    // new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    new Menu (25, 'Expenditure', null, null, 'shop_two', null, true, 0),
    new Menu (26, 'View or create', '/expenditure/view', null, 'shopping_cart', null, false, 25),


    new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),


    // new Menu (35, 'Messages', null, null, 'devices', null, true, 0),
    // new Menu (36, 'Create or Send', '/sms/sms', null, 'mobile_screen_share', null, false, 35),
    // new Menu (37, 'Balance', '/sms/balance', null, 'phonelink_ring', null, false, 35),


    new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),

   ]

   var superviser = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

   // new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    // new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    // new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
   // new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
   // new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    new Menu (19, 'Request loan', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    new Menu (25, 'Expenditure', null, null, 'shop_two', null, true, 0),
    new Menu (26, 'View or create', '/expenditure/view', null, 'shopping_cart', null, false, 25),


    new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),


    new Menu (35, 'Messages', null, null, 'devices', null, true, 0),
    new Menu (36, 'Create or Send', '/sms/sms', null, 'mobile_screen_share', null, false, 35),
    new Menu (37, 'Balance', '/sms/balance', null, 'phonelink_ring', null, false, 35),


    new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),

   ]

   var accountant = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    // new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    // new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

    // new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    // new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    // new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
    // new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
    // new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    new Menu (19, 'Request loan', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    new Menu (25, 'Expenditure', null, null, 'shop_two', null, true, 0),
    new Menu (26, 'View or create', '/expenditure/view', null, 'shopping_cart', null, false, 25),


    new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),


    new Menu (35, 'Messages', null, null, 'devices', null, true, 0),
    new Menu (36, 'Create or Send', '/sms/sms', null, 'mobile_screen_share', null, false, 35),
    new Menu (37, 'Balance', '/sms/balance', null, 'phonelink_ring', null, false, 35),


    new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),

   ]

   if(localStorage.getItem('selected_role')){

        var role1 = localStorage.getItem('selected_role')
        let role2 = JSON.parse(role1)
        let rolename = role2[0].name;
        let rolecode = role2[0].code;
        let word = role2[0].description;
        if(rolename == "Administrator" && rolecode == "ROLE001" && word == "Oversee the System and all Users") {
            return admin
        } else if(rolename == "Member" && rolecode == "ROLE002" && word == "Normal Member of the certain group") {
            return member //superviser
        } else if(rolename == "Superviser" && rolecode == "ROLE003" && word == "Oversee specific group Group") {
            return superviser
        } else if(rolename == "Accountant" && rolecode == "ROLE004" && word == "Group Report and Information") {
            return accountant
        }else {
            return  admin
        }

   } else {
     return member 
   }
     


  
}
      
      //console.log(rolecode)
export const verticalMenuItems =  MenuRoles() 
//export const verticalMenuItems = [
    
    
   
   
//    JSON.parse(localStorage.getItem('menus')).forEach(element => {
      
//        new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0)+","
//        new Menu (element.id,element.title, element.routerLink, element.href, element.icon, element.target, element.hasSubMenu, element.parentId)
//     }),
   

    // new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    // new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    // new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    // new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    // new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    // new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

    // new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    // new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    // new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
    // new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
    // new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    // new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    // new Menu (19, 'Request loan', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    // new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    // new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    // new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    // new Menu (25, 'Expenditure', null, null, 'shop_two', null, true, 0),
    // new Menu (26, 'View or create', '/expenditure/view', null, 'shopping_cart', null, false, 25),


    // new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    // new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    // new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),


    // new Menu (35, 'Messages', null, null, 'devices', null, true, 0),
    // new Menu (36, 'Create or Send', '/sms/sms', null, 'mobile_screen_share', null, false, 35),
    // new Menu (37, 'Balance', '/sms/balance', null, 'phonelink_ring', null, false, 35),


    // new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    // new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),

 ////*************************************** */




   /* new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
    new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
    new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
    new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
    new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
    new Menu (8, 'Tabs', '/ui/tabs', null,Store 'tab', null, false, 3), 
    new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3),
    new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
    new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
    new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
    new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
    new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3), 
    new Menu (15, 'Dynamic Menu', '/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
    new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 0),
    new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 0),
    new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
    new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
    new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
    new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
    new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
    new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
    new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
    new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
    new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
    new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
    new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
    new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
    new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
    new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30), 
    new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
    new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
    new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
    new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
    new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
    new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
    new Menu (48, 'Landing', '/landing', null, 'filter', null, false, 40),
    new Menu (49, 'Profile', null, null, 'person', null, true, 40),
    new Menu (50, 'Projects', '/profile/projects', null, 'note', null, false, 49),    
    new Menu (51, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 49),
    new Menu (55, 'Schedule', '/schedule', null, 'event', null, false, 0),
    new Menu (66, 'Maps', null, null, 'map', null, true, 0),
    new Menu (67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu (68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
    new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
    new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70), 
    new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 0),  
    new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 0),  
    new Menu (140, 'Level 1', null, null, 'more_horiz', null, true, 0),
    new Menu (141, 'Level 2', null, null, 'folder_open', null, true, 140),
    new Menu (142, 'Level 3', null, null, 'folder_open', null, true, 141),
    new Menu (143, 'Level 4', null, null, 'folder_open', null, true, 142),
    new Menu (144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
    new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0)
    */
//]

export const horizontalMenuItems =  MenuRoles() 
    // new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    // new Menu (2, 'Users and Activities', null, null, 'phonelink', null, true, 0), 
    // new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
    // new Menu (4, 'Deposit', '/user/deposit', null, 'shopping_basket', null, false, 2),
    // new Menu (5, 'Other Income', '/user/other-income', null, 'credit_card', null, false, 2),
    // new Menu (6, 'User Deposit Info', '/user/user-deposit', null, 'assignment_ind', null, false, 2),

    // new Menu (8, 'Admin Setting', null, null, 'settings', null, true, 0),
    // new Menu (9, 'New Group', '/setting/create-group', null, 'add_box', null, false, 8),
    // new Menu (10, 'Groups', '/setting/group_list', null, 'view_list', null, false, 8),
    // new Menu (11, 'Group Policies', '/setting/group_policy', null, 'policy', null, false, 8),
    // new Menu (12, 'Report', '/setting/report-Setting', null, 'import_contacts', null, false, 8),


    // new Menu (18, 'User Loan', null, null, 'view_module', null, true, 0), 
    // new Menu (19, 'Request loan', '/loan/request-and-history', null, 'free_breakfast', null, false, 18),
    // new Menu (20, 'Loan approve', '/loan/loan-approve', null, 'check_box', null, false, 18),
    // new Menu (21, 'Loan instead', '/loan/loan-instead', null, 'gamepad', null, false, 18),
    // new Menu (22, 'Loan payment', '/loan/loan-payment', null, 'local_mall', null, false, 18),


    // new Menu (25, 'Expenditure', null, null, 'add_shopping_cart', null, true, 0),
    // new Menu (26, 'Create new', '/expenditure/view', null, 'colorize', null, false, 25),

    // new Menu (29, 'User Profile', null, null, 'account_box', null, true, 0),
    // new Menu (30, 'Edit', '/profile/user-info', null, 'colorize', null, false, 29),
    // new Menu (31, 'Change Password', '/profile/change-password', null, 'vpn_key', null, false, 29),

    // new Menu (35, 'Messages', null, null, 'mail', null, true, 0),
    // new Menu (36, 'Create or Send', '/sms/sms', null, 'sms', null, false, 35),
    // new Menu (37, 'History', '/sms/history', null, 'history', null, false, 35),

    // new Menu (40, 'Reports', null, null, 'weekend', null, true, 0),
    // new Menu (41, 'Open', '/report/user-view', null, 'description', null, false, 40),


    //***************************************** */


/*    new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
    new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
    new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
    new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
    new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
    new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
    new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3), 
    new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
    new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
    new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
    new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
    new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3),
    new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 40), 
    new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 40), 
    new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
    new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
    new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
    new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
    new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
    new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
    new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
    new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
    new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
    new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20),    
    new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
    new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
    new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
    new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30), 
    new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
    new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70), 
    new Menu (66, 'Maps', null, null, 'map', null, true, 70),
    new Menu (67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu (68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
    new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 3), 
    new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 3),
    new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
    new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
    new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
    new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
    new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
    new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
    new Menu (48, 'Landing', '/landing', null, 'filter', null, false, 40),
    new Menu (49, 'Profile', null, null, 'person', null, true, 40),
    new Menu (50, 'Projects', '/profile/projects', null, 'note', null, false, 49),    
    new Menu (51, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 49),
    new Menu (55, 'Schedule', '/schedule', null, 'event', null, false, 40),
    new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 40)
    */
//]