import { Component, OnInit } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import  { ExpenditureService } from './expenditure.service';
import { LoanService} from '../loan/loan.service';
import Swal from 'sweetalert2';
import { UserService } from '../user/user.service';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-expenditure',
  templateUrl: './expenditure.component.html',
  styleUrls: ['./expenditure.component.scss']
})
export class ExpenditureComponent implements OnInit {


  public formData:FormGroup;

  defaultYear = null
  public selectedYear = null
  amountBoxInput = false
  p: number = 1;
  public periods =  null
  public group_id = null
  itemsPerPage = 5
  expenditureForm ={}
  expenditureLists = null
  notFoundStatus = false
  eligibility = false
  accounts = [
    { name: 'Jamii' },
    { name: 'Hisa' }
  ];


  constructor(
    public appSettings:AppSettings,
    private expenditureService:ExpenditureService,
    public dialog: MatDialog,
    private messageService:MessageService,
    private loanService:LoanService,
    private userService:UserService,
    private authService:AuthenticationService,
    private expeditureService:ExpenditureService,
    private formBuilder: FormBuilder,
    
  ) { }

  ngOnInit() {

    this.group_id = this.authService.currentUserGroup()
    var y = new Date();
    this.selectedYear = y.getFullYear()
    this.fetchDefaultYear()
    this.findDefaultDepositPeriod(this.selectedYear)
    this.fetchExpenditure()
    this.checkRoles()


    this.formData = this.formBuilder.group({
      'amount': ['', Validators.required],
      'title': ['', Validators.required],
      'description': ['', Validators.required],
      'periodname': ['', Validators.required],
      'account': ['', Validators.required],
  });


  }

  

  fetchExpenditure(){
    this.expenditureService.fetchExpenditure().subscribe(response =>{
      if(response.status == "401"){
        this.notFoundStatus = true
      
      }else{
        this.expenditureLists = response
        this.notFoundStatus = false
      }
    })

    this.messageService.getMessage().subscribe(data =>{
      this.expenditureService.fetchExpenditure().subscribe(response =>{
        if(response.status == "401"){
          this.notFoundStatus = true
        
        }else{
          this.expenditureLists = response
          this.notFoundStatus = false
        }
      })

    })
  }


  fetchDefaultYear(){
    this.loanService.fetchDefaultYear().subscribe(response =>{
        this.defaultYear = response
    })
}


findDefaultDepositPeriod(fullYear){
    this.userService.findDefaultDepositPeriod(this.group_id,fullYear).subscribe(
        response =>{
          this.periods = response;
          //this.onChangePeriod(response.id)
          let periodId = JSON.stringify(response[0].id)
         // this.onChangePeriod(periodId)
        })
}

onSelectPeriod(periodValue){
    if(periodValue){
       this.amountBoxInput = true
    }else{
        this.amountBoxInput = false
    }
    
}

saveExpenditure(formObject){
  
  formObject["group_id"] = this.group_id;
  this.expeditureService.savedata(formObject).subscribe(response =>{
    this.messageService.setMessage("ddd")
    Swal.fire(
      'Success!',
      'Expenditure  has been created.',
      'success'
    )
   
  })
 
  this.formData.reset();
}

delete(id){
   
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to delete this",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
      this.deleteExpenditure(id)
    }
  })

}

deleteExpenditure(id){
  this.expenditureService.deleteExpenditure(id).subscribe(response =>{
    if(response.status == "200"){
      this.messageService.setMessage("ddd")
      Swal.fire(
        'Deleted!',
        'Expenditure  has been deleted.',
        'success'
      )
    }else{

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      })

    }
  })
}


checkRoles() {
  var role1 = localStorage.getItem('selected_role')
  let role2 = JSON.parse(role1)
  let rolename = role2[0].name;
  let rolecode = role2[0].code;
  let word = role2[0].description;
  /////eligibility
  if(rolename == "Administrator" && rolecode == "ROLE001" && word == "Oversee the System and all Users") {
  this.eligibility = true
  } 
  else if(rolename == "Superviser" && rolecode == "ROLE003" && word == "Oversee specific group Group"){
    this.eligibility = true
  }
  else if(rolename == "Accountant" && rolecode == "ROLE004" && word == "Group Report and Information"){
    this.eligibility = true
  } 
  else{
    this.eligibility = false

  }

}




}
