import { Component,Inject, OnInit } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2'
import { SettingService } from '../../setting.service';

@Component({
    selector: 'app-create-and-edit-report.component',
    templateUrl: './create-and-edit-report.component.html',
    styleUrls: ['../../setting.component.scss']
})

export class CreateAndEditReportComponent implements OnInit{

    public reportForm:FormGroup;
    
    roles = [
        { name: 'Administrator' },
        { name: 'Member' },
        { name: 'Superviser' },
        { name: 'Accountant	' }
      ];

    constructor(
        public dialogRef: MatDialogRef<CreateAndEditReportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private settingService:SettingService
        ){}

        ngOnInit() {
            this.reportForm = this.formBuilder.group({
                'name': ['', Validators.required],
                'authority': ['', Validators.required],
                'url': ['', Validators.required],
                'description': ['', Validators.required]
              });
        }


         cancel(): void {
            this.reportForm.reset()
            this.settingService.reportForm.reset()
            this.dialogRef.close();
          }

          onSubmit(){
            let datainfo = this.settingService.reportForm.value
            if (this.settingService.reportForm.get('id').value) {
                this.saveEditReport(datainfo)
              }
              else{
                this.savaReportData(datainfo)
               // console.log(datainfo)
              }
          }

        savaReportData(datainfo){
            this.settingService.savaReportData(datainfo).subscribe(response =>{
               if(response.status == "200"){

                this.reportForm.reset()
                this.settingService.reportForm.reset()

                Swal.fire({
                    icon: 'success',
                    title: "Report saved",
                    showConfirmButton: false,
                    timer: 2000
                  })

               }
               else{

                Swal.fire({
                    icon: 'error',
                    title: "report not saved",
                    showConfirmButton: false,
                    timer: 2000
                  })

               }
            })
            this.messageService.setMessage("ddd")
            this.cancel()
        }

        saveEditReport(data){
            this.settingService.saveEditReport(data).subscribe(response=>{
                if(response.status == "200"){
                    this.messageService.setMessage("ddd")
                    this.reportForm.reset()
                    this.settingService.reportForm.reset()
                     this.cancel()

                    Swal.fire({
                        icon: 'success',
                        title: "Report saved",
                        showConfirmButton: false,
                        timer: 2000
                      })
    
                   }
                   else{
    
                    Swal.fire({
                        icon: 'error',
                        title: "report not saved",
                        showConfirmButton: false,
                        timer: 2000
                      })
    
                   }


            })
        }

}