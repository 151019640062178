import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private http:HttpClient) { }

  fetchAllUsers(){
    return this.http.get<any>(`/financial/api/v1/fetch-uses-within-group`)
  }

  sendMessge(data){
    return this.http.post<any>(`/financial/api/v1/send-message`,data)
  }

  fetchMessageSent(){
    return this.http.get<any>(`/financial/api/v1/message-sent`)
  }
  deleteFromDataBase(id){
    return this.http.delete<any>(`/financial/api/v1/delete-message-sent/${id}`)
  }
  fetchMessageBalance(){
    return this.http.get<any>(`/financial/api/v1/message-balance`)
    //return this.http.get<any>(`/bulk?do=balance&username=emmachief&password=241989`)
  }
  resend(mobile,body){
    return this.http.get<any>(`/financial/api/v1/message-resend/${mobile}/${body}`)
  }

  sendMessageToOtherNumber(dataObject){
    return this.http.post<any>(`/financial/api/v1/message-to-other-mobile`,dataObject)
  }


}
