import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-error-message-dialog',
    templateUrl: './error-message-dialog.html',
    styleUrls: ['../setting.component.scss']
  })

  export class ErrorMessageDialog {
    constructor( private dialogRef: MatDialogRef<ErrorMessageDialog>) {}
    
    onNoClick(): void {
        this.dialogRef.close();
      }


  }