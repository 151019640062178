import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ExpenditureService {

  constructor(private http:HttpClient) { }

  fetchExpenditure(){
    return this.http.get<any>(`/financial/api/v1/fetch-expenditure`)
  }

  savedata(data){
   // console.log(data)
    return this.http.post<any>(`/financial/api/v1/save-expenditure`,data)
  }

  deleteExpenditure(id){
    return this.http.delete<any>(`/financial/api/v1/delete-expenditure/${id}`)
  }
}
