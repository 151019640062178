import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule,MatFormFieldModule,MatTableModule } from '@angular/material';
import { DialogOverviewExampleDialog }  from './user-list.component';
import { CreateEditUserDialogComponent } from './create-edit-user-dialog.component'
import { enableAndDisableUserDialog } from './enable-and-disable-user-dialog';
import { AddRemoveRolesDialog } from './add-remove-roles-dialog';
import {NgxPaginationModule} from 'ngx-pagination';
import { from } from 'rxjs';
export const routes = [
  {path:'User-list',component:UserListComponent,pathMatch: 'full'}
]



@NgModule({
  declarations: [
    UserListComponent,
    DialogOverviewExampleDialog,
    CreateEditUserDialogComponent,
    enableAndDisableUserDialog,
    AddRemoveRolesDialog
  ],
  entryComponents: [
    DialogOverviewExampleDialog,
    CreateEditUserDialogComponent,
    enableAndDisableUserDialog,
    AddRemoveRolesDialog
  ],

  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTableModule,
    NgxPaginationModule
  ]
})
export class UserListModule { }
