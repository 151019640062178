import { Component, OnInit,ViewChild,Inject,AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { SettingModule } from '../../setting/setting.module';
import { AppSettings } from 'src/app/app.settings';
import { MessageService } from 'src/app/service/message.service';
import { UserListService } from './user-list.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import Swal from 'sweetalert2'


@Component({
selector: 'app-create-edit-user-dialog.component',
  templateUrl: './create-edit-user-dialog.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class CreateEditUserDialogComponent implements OnInit{
  public form:FormGroup;
  public roles = [];
  public groups = [];
 


  public identityType = [
    { id: 1, name: 'Kadi ya Mpiga Kura' },
    { id: 2, name: 'Kitambulisha Cha Taifa' },
    { id: 3, name: 'Passsport ya Kusafiria' },
    { id: 3, name: 'Lesseni Ya Udereva' }
  ];

    constructor(
      public appSettings:AppSettings,
      public dialog: MatDialog,
      private messageService:MessageService,
      private service:UserListService,
      private formBuilder: FormBuilder,
      private authService: AuthenticationService,
      private userListService : UserListService,
      public dialogRef: MatDialogRef<CreateEditUserDialogComponent>,
      ){}

    ngOnInit() {
      this.form = this.formBuilder.group({
        'id': ['', Validators.required],
        'fname': ['', Validators.required],
        'mname':['', Validators.required],
        'sname': ['', Validators.required],
        'id_type': ['', Validators.required],
        'id_no': ['', Validators.required],
        'email': ['', Validators.compose([Validators.required, emailValidator])],
        'enabled': ['', Validators.required],
        'mobile': ['', Validators.required],
        //'roles': ['', Validators.required],
        'box': ['', Validators.required],
        'group_id': ['', Validators.required],
        
      });


     // console.log("ffffffff "+JSON.stringify(this.userListService.form.value))
      this.fetchRoles()
      this.fetchCurrentUserGroup()
   }


   fetchRoles(){
     let roleCurrentUser = this.authService.getSelectedRole();
     let roleC = JSON.parse(roleCurrentUser);
     this.userListService.fetchRoles(roleC[0].name).subscribe(
       data=>{this.roles = data},
       error =>{console.log(error)}
  )}

  fetchCurrentUserGroup(){
      let roleCurrentUser = JSON.parse(this.authService.getSelectedRole());
      if(roleCurrentUser[0].name == "Administrator"){
      this.userListService.fetchGroupsByCurrentUser(roleCurrentUser[0].name).subscribe(
      response =>{
        this.groups = response 
        //console.log(roleCurrentUser[0].name)
      },
      error =>{console.log(error)})

    }else{
      let currentUserGroup = JSON.parse(this.authService.group());
      let group_id = currentUserGroup[0].id
      this.userListService.fetchGroupsByCurrentUser(group_id).subscribe(
      response =>{
        this.groups = response
      },
      error =>{console.log(error)}
      )}
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.userListService.form.reset()
  }

  ngAfterViewInit(){
    console.log("after view init")
  }
 

  submitForm(){
      let data = this.service.form.value;
        if (this.service.form.get('id').value ) {
           let userId = data.id
           this.userListService.updateUser(data,userId).subscribe(
           response =>{
              // console.log(response)
               
               this.onNoClick()

               Swal.fire({
                icon: 'success',
                title: 'Information changed',
                showConfirmButton: false,
                timer: 2000
              })

              this.messageService.setMessage("this is msg")
            },
             error =>{
              Swal.fire({
                icon: 'error',
                title: 'Error while saving changed',
                showConfirmButton: false,
                timer: 2000
              })

             }
           )
        }else{
          
          let info = data
          let id = info.id
          let fname = info.fname
          let mname = info.mname
          let sname = info.sname
          let email = info.email
          let mobile = info.mobile
          if(id == "" && fname != "" && mname != "" && sname != "" && email != "" && mobile != ""){
            this.userListService.createUser(data).subscribe(
            response =>{
              this.onNoClick()
             // console.log(response)
             Swal.fire({
              icon: 'success',
              title: 'User created',
              showConfirmButton: false,
              timer: 2000
            })
           // location.reload();
              
               setTimeout(function(){
                location.reload();
              },3000);
               
                //  this.onNoClick()
                //  let dialogRef = this.dialog.open(successMessageDialog,{
                //   height:'400px',
                //   width:'800px'
                // });

                this.messageService.setMessage("this is msg")
            },
                error =>{
                  Swal.fire({
                    icon: 'error',
                    title: 'Status not changed',
                    showConfirmButton: false,
                    timer: 2000
                  })

                }
            )
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              showConfirmButton: false,
              timer: 2000
            })

          }
        }
    }


  }


  export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
  }