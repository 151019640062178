import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserListService } from './user-list.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { CreateEditUserDialogComponent } from './create-edit-user-dialog.component';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { successMessageDialog } from '../../setting/message/success-message-dialog';
import { ErrorMessageDialog } from '../../setting/message/error-message-dialog'
import Swal from 'sweetalert2'


@Component({
    selector: 'app-add-remove-roles-dialog',
    templateUrl: './add-remove-roles-dialog.html',
    styleUrls: ['./user-list.component.scss']
})



export class AddRemoveRolesDialog{
    roles = []
    rolesFounds = null

    public roleForm:FormGroup;
    id = null
    statusCodestatus =""
    statusSms = ""
    statusVisible = false
   
 

    constructor(
        public dialogRef: MatDialogRef<AddRemoveRolesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appSettings:AppSettings,
        private authService: AuthenticationService,
        private userlist:UserListService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private messageService:MessageService)
        {}


    ngOnInit() {

        this.roleForm = this.formBuilder.group({
            'roleId': ['', Validators.required]

            
        });

       // console.log("vvvvvvvvvvvv"+this.data.id)
         

        
        

        var userId = this.data.id
        var currentUserRole =  this.authService.currentUserRole()
        var currentUserGroupId = this.authService.currentUserGroup();
        this.userlist.rolesFoundToUser(userId).subscribe(
        response =>{
            this.rolesFounds = response[0].roles
        })


        this.messageService.getMessage().subscribe((data2)=>{

            this.userlist.rolesFoundToUser(userId).subscribe(
                response =>{
                    this.rolesFounds = response[0].roles
               })
        })



        this.userlist.fetchRoles(currentUserRole).subscribe(
        response =>{
            this.roles = response  
        })

    }


 onNoClick(): void {
 this.dialogRef.close();
 }

 public onSubmit(values):void {
     let roleId = JSON.stringify(values.roleId)
     let userId = this.data.id;
     this.userlist.addRoleToUser(roleId,userId).subscribe(
         response =>{
            // console.log(response)
             if(response.status == 401){
                this.statusCodestatus = "error"
                this.statusSms = response.message
                this.statusVisible = true
                Swal.fire({
                    icon: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 2000
                  })
                // setTimeout(function(){
                //     this.statusVisible = false
                //   },3000);
             }else{
                this.statusCodestatus = "success"
                this.statusSms = response.message
                this.statusVisible = true
                Swal.fire({
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 2000
                  })
                
             }
              
            }
     )

     this.messageService.setMessage("msg")
     this.roleForm.reset()
 }

 removeRole(roleId){
  let userId = this.data.id;
  this.userlist.removeRoleFromUser(roleId,userId).subscribe(
      response =>{
          // console.log(response)
          if(response.status == 401){
            this.statusCodestatus = "error"
            this.statusSms = response.message
            this.statusVisible = true
            Swal.fire({
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
              })

            
         }else{
            this.statusCodestatus = "success"
            this.statusSms = response.message
            this.statusVisible = true
            Swal.fire({
                icon: 'success',
                title: 'Role removed from user',
                showConfirmButton: false,
                timer: 2000
              })
         }


      })
      this.messageService.setMessage("msg")
 }

 


 //https://sweetalert2.github.io/

    

}