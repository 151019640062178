import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2'
import { SmsService } from '../sms.service';

@Component({
    selector: 'add-send-to-other-dialog.component',
    templateUrl: './send-to-other-dialog.component.html',
    styleUrls: ['../sms.component.scss']
})

export class SendTOOtherDialogComponent{
    public otherNumberForm:FormGroup;
    public onltyNumberForm:FormGroup;
    mobileNumberArray = [];
    mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
    
    constructor(
    public dialogRef: MatDialogRef<SendTOOtherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private smsService:SmsService
    ){}

    ngOnInit(){
        this.otherNumberForm = this.formBuilder.group({
            //'number': ['', Validators.required],
            'body': ['', Validators.required],
        });
        this.onltyNumberForm = this.formBuilder.group({
            'number': ['', Validators.required]
        });
   
    }

    cancel(): void {
        this.dialogRef.close();
    }

    onChange(value){
        let length = value.length
        if(length == 12){
            this.mobileNumberArray.push(value)       
            console.log("hh"+this.mobileNumberArray)
            
            this.onltyNumberForm.reset()
        }  
    }

    deleteArray(idex){
      this.mobileNumberArray.splice(idex,1);
    }

    submitForm(){
      

        let numbers = JSON.stringify(this.mobileNumberArray)

        var dataObject = {
            "body": this.otherNumberForm.value.body,
            "numbers": JSON.parse(numbers)
        }
        //console.log(dataObject)
        //var ar = fruits.split(', ');
        
        let body = this.otherNumberForm.value.body
        //let numbers = JSON.stringify(this.mobileNumberArray)
        let mm =JSON.parse(numbers)
        this.smsService.sendMessageToOtherNumber(dataObject).subscribe(response =>{
            console.log("FFF "+response)
            this.messageService.setMessage("kk")
            this.otherNumberForm.reset()
            this.onltyNumberForm.reset()
            this.cancel()
            Swal.fire({
                icon: 'success',
                title: "Message Sent",
                showConfirmButton: false,
                timer: 2000
              })

        })
    }

}