import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http:HttpClient) { }

  ferchAllReports(){
      return this.http.get<any>(`/financial/api/v1/fetch-all-reports`);
  }

  findDefaultDepositPeriod(group_id,year){
    return this.http.get<any>(`/financial/api/v1/find-group-deposit-period/${group_id}/${year}`)
  }

  creditAndDeposit(dateFrom,dateTo,reportName){
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    return  this.http.get<any>(`/financial/api/v1/general-report/${dateFrom}/${dateTo}/${reportName}`,httpOptions);
  }
}
