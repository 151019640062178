import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/service/authentication.service';
import {FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class UserListService {

  constructor(private authService: AuthenticationService,private http:HttpClient) { }

  form: FormGroup = new FormGroup({
    id        : new FormControl(''),
    fname        : new FormControl(''),
    mname      : new FormControl('', [Validators.required]),
    sname : new FormControl('', [Validators.required]),
    id_type : new FormControl('', [Validators.required]),
    id_no        : new FormControl(''),
    enabled        : new FormControl(''),
    mobile      : new FormControl('', [Validators.required]),
    email : new FormControl('', [Validators.required]),
   // roles   : new FormControl('', [Validators.required]),
    group_id   : new FormControl('', [Validators.required]),
    
  });

 
  fetchUsers(filter){
    let  accessToken = this.authService.accessToken();
    
   return this.http.get<any>(`/financial/api/v1/users-and-group/${filter}`)
  }

  fetchRoles(role){
    return this.http.get<any>(`/financial/api/v1/roles/${role}`)
  }

  populateForm(data) {
    this.form.setValue(data);
  }

  

  fetchGroupsByCurrentUser(filter){
    return this.http.get<any>(`/financial/api/v1/group-by-current-user/${filter}`)
  }

  createUser(userData){
    return this.http.post<any>(`/financial/api/v1/create-user`,userData)
  }

  updateUser(userData,userId){
    return this.http.post<any>(`/financial/api/v1/update-user/${userId}`,userData)
  }

  activateDeactivateUser(userId,action){
    return this.http.get<any>(`/financial/api/v1/activate-diactivate-user/${userId}/${action}`)
  }

  rolesFoundToUser(userId){
    return this.http.get<any>(`/financial/api/v1/roles-found-to-user/${userId}`)
  }

  addRoleToUser(roleId,userId){
    return this.http.get<any>(`/financial/api/v1/add-role-to-user/${roleId}/${userId}`)
  }

  removeRoleFromUser(roleId,userId){
    return this.http.get<any>(`/financial/api/v1/remove-role-from-user/${roleId}/${userId}`)

  }

}
