import { Component, OnInit } from '@angular/core';
import { SmsService } from '../sms.service';

@Component({
  selector: 'app-sms-balance',
  templateUrl: './sms-balance.component.html',
  styleUrls: ['./sms-balance.component.scss']
})
export class SmsBalanceComponent implements OnInit {
   smsBalance = null
  constructor(private smsService:SmsService) { }

  ngOnInit() {
    this.fetchMessageBalance()
  }

  fetchMessageBalance(){
   this.smsService.fetchMessageBalance().subscribe(response =>{
     this.smsBalance = response
   },error =>{
     //this.smsBalance = error.error.text
     let str = error.error.text
     this.smsBalance = str.substring(3, 12)
    
    })
  }

  

}
