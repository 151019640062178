import { Component, OnInit } from '@angular/core';
import { ReportService } from './report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss']
})
export class GeneralReportComponent implements OnInit {

  private reports =  null
  defaultYear = null
  defaultYearTo = null
  public selectedYear = null
  public selectedYearTo = null
  public periods =  null
  public periodsTo =  null
  periodForm:Object = {}
  reportUrl = null

  periodFromName = null
  periodToName = null
  reportName = null
  showIframe = false

  constructor(private reportService:ReportService,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.allReports()
    //this.printReport()

    var y = new Date();
    let fullYear = 2100//= y.getFullYear()
    this.findingDefaultYear(fullYear)
    this.findingDefaultYearTo(fullYear)
    this.selectedYear = y.getFullYear()
    this.findDefaultDepositPeriod(this.selectedYear)
    this.selectedYearTo = y.getFullYear()
    this.findDefaultDepositPeriodTo(this.selectedYearTo)


  }

  allReports() {
    this.reportService.ferchAllReports().subscribe(response =>{
       this.reports = response
       console.log(response)
    })
  }
  findingDefaultYear(fullYear){
    var y = new Date();
    var yearArray = [];
    for( let year = 2014;year <= fullYear;year++){
         yearArray.push(year)
        }
    this.defaultYear = yearArray;
  }


  findingDefaultYearTo(fullYear){
    var y = new Date();
    var yearArray = [];
    for( let year = 2014;year <= fullYear;year++){
         yearArray.push(year)
        }
    this.defaultYearTo = yearArray;
  }

  findDefaultDepositPeriod(fullYear){
    let group_id = 0
    this.reportService.findDefaultDepositPeriod(group_id,fullYear).subscribe(
      response =>{
        this.periods = response;
      })
  }

  findDefaultDepositPeriodTo(fullYear){
    let group_id = 0
    this.reportService.findDefaultDepositPeriod(group_id,fullYear).subscribe(
      response =>{
        this.periodsTo = response;
      })
  }

  printReport(){
    if(this.periodFromName != null && this.periodToName != null && this.reportName != null){
      this.reportService.creditAndDeposit(this.periodFromName,this.periodToName,this.reportName).subscribe(response =>{
        // let file = new Blob([response], { type: 'application/pdf'});
        // let fileURL = URL.createObjectURL(file);
        // this.reportUrl = fileURL
        // window.open(fileURL,"_blank");

      this.showIframe = true
      let file = new Blob([response], { type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      this.reportUrl= this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      },error =>{
        let str = error.error.text
        //console.log("vvv"+str)
      })
    } else {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Make sure all fields are selected'
      })

    }
  }

  onChangePeriodFrom(periodFromName){
    this.periodFromName = periodFromName
  }
  onChangePeriodTo(periodToName){
    this.periodToName = periodToName
  }
  onChangeReportName(reportName){
   this.reportName = reportName
  }

  



}
