import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  mimi = null
  constructor(private http:HttpClient) { }

  formDeposit: FormGroup = new FormGroup({
    basic        : new FormControl(''),
    mobile        : new FormControl(''),
    periodname        : new FormControl(''),
    email        : new FormControl(''),
    fname        : new FormControl(''),
    mname        : new FormControl(''),
    sname        : new FormControl(''),
    period_id        : new FormControl(''),
    created_at        : new FormControl(''),
    updated_at        : new FormControl(''),
    moth        : new FormControl(''),
    year        : new FormControl(''),
    user_id        : new FormControl(''),
    date        : new FormControl(''),
    id        : new FormControl(''),
    group_id        : new FormControl(''),
    other        : new FormControl('')
  });

  formOtherSource: FormGroup = new FormGroup({
    amount        : new FormControl(''),
    id        : new FormControl(''),
    title        : new FormControl(''),
    account        : new FormControl(''),
    description        : new FormControl(''),
    group_id        : new FormControl(''),
    period_id        : new FormControl(''),
    date        : new FormControl(''),
    month        : new FormControl(''),
    year        : new FormControl(''),
  });

  basicTotal = null
  otherTotal = null


  formDepositPopulate(data){
    this.formDeposit.setValue(data);
  }

  formOtherSourcePopulate(data){
    this.formOtherSource.setValue(data);
  }

  

  showUserdetail(){
    return "String"
  }
  findDefaultDepositPeriod(group_id,year){
    return this.http.get<any>(`/financial/api/v1/find-group-deposit-period/${group_id}/${year}`)
  }

  fetchDepositedUsers(group_id,period_id){
  // this.http.post<any>(`/financial/api/v1/fetch-deposited-user-by-period/${group_id}/${period_id}`)
  return this.http.get<any>(`/financial/api/v1/fetch-deposited-user-by-period/${group_id}/${period_id}`)
  }

  activateUsersDeposit(group_id,period_id){
    return this.http.get<any>(`/financial/api/v1/activate-users-to-deposit/${group_id}/${period_id}`)
  }

  updateDeposit(deposit_id,basic,other){
    //console.log(deposit_id)
    return this.http.get<any>(`/financial/api/v1/update-deposit/${deposit_id}/${basic}/${other}`)
  }

  fetchOtherSource(deposit_id){
    return this.http.get<any>(`/financial/api/v1/fetch-other-source/${deposit_id}`)
  }

  createOtherSource(amount,title,description,account){
    //create-other-source
    return this.http.get<any>(`/financial/api/v1/create-other-source/${amount}/${title}/${description}/${account}`)
  }

  update(data){
    return this.http.put<any>(`/financial/api/v1/update-other-source/`,data)
  }

  delete(other_source_id){
    return this.http.delete<any>(`/financial/api/v1/delete-other-source/${other_source_id}`)
  }

  fetchPersonalDeposit(){
    return this.http.get<any>(`/financial/api/v1/person-deposit`);
  }

  populateBasicSum(sum){
   this.basicTotal = sum;
   // console.log("ffffffff"+sum)
  }

  populateOtherSum(sum){
    this.otherTotal = sum;
  }

  singleUserDeposit(){
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    return  this.http.get<any>(`/financial/api/v1/credit-debit`,httpOptions);
  }


}
