import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../user.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';


import Swal from 'sweetalert2'

@Component({
    selector: 'app-amount-edit-dialog.component',
    templateUrl: './amount-edit-dialog.component.html',
    styleUrls: ['../deposit.component.scss']
})

export class AmountEditDialogComponent{
    public formDeposit:FormGroup;
    editForm = {}

 constructor(
    public dialogRef: MatDialogRef<AmountEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public userService:UserService,
    private messageService: MessageService
    ){}

    ngOnInit(){
        this.formDeposit = this.formBuilder.group({
            'basic': ['', Validators.required],
            'other': ['', Validators.required],
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
      }

      submitForm(){
         let data = this.userService.formDeposit.value;
         let depositId = data.id
         let basic = data.basic
         let other = data.other
         this.userService.updateDeposit(depositId,basic,other).subscribe(
             response =>{
                 if(response.status == 200){
                   
                    this.messageService.setMessage("sss")
                    this.onNoClick()
                    Swal.fire({
                        icon: 'success',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 2000
                      })

                 }else{

                    Swal.fire({
                        icon: 'error',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 2000
                      })

                 }
             },
            error =>{
                 
                Swal.fire({
                    icon: 'error',
                    title: 'Error while saving changed',
                    showConfirmButton: false,
                    timer: 2000
                  })
            }
         )
      }

}