import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { SmsService } from './sms.service';
import { MessageService } from 'src/app/service/message.service';
import { SendTOOtherDialogComponent } from './other-number-model/send-to-other-dialog.component'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {

  smsList = null
  p: number = 1;
  itemsPerPage =10

  public smsForm:FormGroup;
  //public bankMultiFilterCtrl: FormControl = new FormControl();
  userList = null
  constructor(
    private formBuilder: FormBuilder,
    private smsService:SmsService,
    private messageService:MessageService,
    public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.smsForm = this.formBuilder.group({
      'body': ['', Validators.required],
      'users': ['', Validators.required],
      'includeName': ['']
    });

    this.fetchAllUser()
    this.fetchMessageSent()
  }

  fetchAllUser(){
    this.smsService.fetchAllUsers().subscribe(response =>{
     this.userList = response
    })
  }

  onSubmit(data){
    this.smsService.sendMessge(data).subscribe(response =>{
      this.messageService.setMessage("iii")
      this.smsForm.reset()
      Swal.fire({
        icon: 'success',
        title: "Message Sent",
        showConfirmButton: false,
        timer: 2000
      })
     })
  }

  fetchMessageSent(){
    this.smsService.fetchMessageSent().subscribe(response =>{
     /// console.log(response)
      this.smsList = response
    })

    this.messageService.getMessage().subscribe(data =>{
      this.smsService.fetchMessageSent().subscribe(response =>{
        this.smsList = response
      })

    })

  }

  delete(id?){

    if(id){
      Swal.fire({
        title: 'You want to delete this Message?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        this.deleteFromDataBase(id)
        if (result.value) {
          Swal.fire(
            'Deleted!',
            'SMSsms has been deleted.',
            'success'
          )
        }
      })
      
    }else{

      Swal.fire({
        title: 'You want to delete all Message?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          let id = null
          this.deleteFromDataBase(id)
          Swal.fire(
            'Deleted!',
            'SMS has been deleted.',
            'success'
          )
        }
      })///

    }

  }

  deleteFromDataBase(id){
    this.smsService.deleteFromDataBase(id).subscribe(response =>{
      this.messageService.setMessage('gggg')
     console.log(response)
    })
  }

  resendDialog(data){

    Swal.fire({
      title: 'You want to resend Message?',
      text: "Please confirm",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.resend(data)
    
      }
    })///

  }

  resend(data){
    //console.log(data.users)
    this.smsService.resend(data.users,data.body).subscribe(response =>{
     if(response.status == "200"){
       this.messageService.setMessage("dd")
      Swal.fire({
        icon: 'success',
        title: "Message Sent",
        showConfirmButton: false,
        timer: 2000
      })

     }else{

      Swal.fire({
        icon: 'error',
        title: "Message not sent",
        showConfirmButton: false,
        timer: 2000
      })
     }

    })
  }


  sendToOther(){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '700px'
    let dialogRef = this.dialog.open(SendTOOtherDialogComponent,dialogconfig );

  }

}
