import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-success-message-dialog',
    templateUrl: './success-message-dialog.html',
    styleUrls: ['../setting.component.scss']
  })

  export class successMessageDialog {
    constructor( private dialogRef: MatDialogRef<successMessageDialog>) {}
    
    onNoClick(): void {
        this.dialogRef.close();
      }


  }