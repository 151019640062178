import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService} from '../../loan.service';
import Swal from 'sweetalert2'
import { UserService } from 'src/app/pages/user/user.service';

@Component({
    selector: 'app-loan-on-behalf-request-dialog.component',
    templateUrl: './loan-on-behalf-request-dialog.component.html',
    styleUrls: ['../loan-instead.component.scss']
})

export class LoanOnBehalfRequestDialogComponent{
    defaultYear = null
    public selectedYear = null
    public group_id = null
    public periods =  null
    amountBoxInput = false
    p: number = 1;
    itemsPerPage = 5
    periodForm ={}

    constructor(
        public dialogRef: MatDialogRef<LoanOnBehalfRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private loanService:LoanService,
        private authService:AuthenticationService,
        private userService:UserService
    ){}

    ngOnInit() {
        this.group_id = this.authService.currentUserGroup()
        var y = new Date();
        this.selectedYear = y.getFullYear()
        this.fetchDefaultYear()
        this.findDefaultDepositPeriod(this.selectedYear)
    }

    fetchDefaultYear(){
        this.loanService.fetchDefaultYear().subscribe(response =>{
            this.defaultYear = response
        })
    }


    findDefaultDepositPeriod(fullYear){
        this.userService.findDefaultDepositPeriod(this.group_id,fullYear).subscribe(
            response =>{
              this.periods = response;
              //this.onChangePeriod(response.id)
              let periodId = JSON.stringify(response[0].id)
             // this.onChangePeriod(periodId)
            })
    }

    onSelectPeriod(periodValue){
        if(periodValue){
           this.amountBoxInput = true
        }else{
            this.amountBoxInput = false
        }
        
    }

    saveLoan(memberId){
        
        let form:any = this.periodForm
        let periodname = form.periodname
        let loanAmount = form.amount
       
       this.loanService.loanCreatedByAdmin(loanAmount,periodname,memberId).subscribe(response =>{
           if(response.status =="401"){
            Swal.fire({
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 2000
              })
           
        
           }else{
           this.periodForm = ""
           this.messageService.setMessage("hh")
          
            Swal.fire({
                icon: 'success',
                title: response.message,
                showConfirmButton: false,
                timer: 2000
              })

          

           }
           

       })
    }

    



    cancel(): void {
        this.dialogRef.close();
    }


    


    
}