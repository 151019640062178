import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenditureComponent } from './expenditure.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule,MatInputModule } from '@angular/material';
import {NgxPaginationModule} from 'ngx-pagination';

export const routes = [
  {path:'view',component:ExpenditureComponent,pathMatch: 'full'},
]



@NgModule({
  declarations: [
    ExpenditureComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    NgxPaginationModule
  ]
})
export class ExpenditureModule { }
