import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../user.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { OtherIncomeComponent } from '../other-income.component';
import Swal from 'sweetalert2'

@Component({
    selector: 'add-edit-dialog-component',
    templateUrl: './add-edit-dialog-component.html',
    styleUrls: ['../other-income.component.scss']
})

export class AddEditDialogComponent{
    public formOtherSource:FormGroup;
    accounts = [
      { name: 'Jamii' },
      { name: 'Hisa' }
    ];

    constructor(
    public dialogRef: MatDialogRef<AddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userService:UserService,
    private messageService: MessageService
    ){}

    ngOnInit(){
        this.formOtherSource = this.formBuilder.group({
            'amount': ['', Validators.required],
            'title': ['', Validators.required],
            'description': ['', Validators.required],
            'account': ['', Validators.required],
        });

       /// console.log("Value fro Parent is "+ OtherIncomeComponent.toChildValue())
    }

    onNoClick(): void {
        this.dialogRef.close();
      }

      submitForm(){
        let form_info = this.userService.formOtherSource.value
        if (!this.userService.formOtherSource.get('id').value) {

        
          let amount = form_info.amount;
          let title = form_info.title;
          let description = form_info.description;
          let account = form_info.account;
          this.userService.createOtherSource(amount,title,description,account).subscribe(
              response =>{
                  this.messageService.setMessage("ieieie")
                  this.onNoClick()
                  Swal.fire({
                    icon: 'success',
                    title: "Other Source Information saved",
                    showConfirmButton: false,
                    timer: 2000
                  })
                  this.userService.formOtherSource.reset()
                })


        }else{

            this.userService.update(form_info).subscribe(data => {
                this.messageService.setMessage("ieieie")
                  console.log(data);
                 this.onNoClick();
                 Swal.fire({
                    icon: 'success',
                    title: "Other Source Information saved",
                    showConfirmButton: false,
                    timer: 2000
                  })
                  this.userService.formOtherSource.reset()
              });
              
        }
          
      }

///createOtherSource

}