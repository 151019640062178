import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserService } from '../user.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { AddEditDialogComponent} from './modal/add-edit-dialog-component'
import Swal from 'sweetalert2'
import { from } from 'rxjs';

@Component({
  selector: 'app-other-income',
  templateUrl: './other-income.component.html',
  styleUrls: ['./other-income.component.scss']
})
export class OtherIncomeComponent implements OnInit {

  defaultYear = null
  public selectedYear = null
  public group_id = null
  public periods =  null
  periodForm:Object = {}
  otherSourceLists = null
  p: number = 1;
  itemsPerPage =10
  
  periodOrg = null

  constructor(

    public appSettings:AppSettings,
    private authService:AuthenticationService,
    private userService :UserService,
    public dialog: MatDialog,
    private messageService:MessageService


  ) { }

  ngOnInit() {
    this.group_id = this.authService.currentUserGroup()
    var y = new Date();
    let fullYear = 2100 //= y.getFullYear()
    this.findingDefaultYear(fullYear)
    this.selectedYear = y.getFullYear()//fullYear
    this.findDefaultDepositPeriod(this.selectedYear)//fullYear
  }

  findingDefaultYear(fullYear){
    
    var y = new Date();
    var yearArray = [];
    for( let year = 2014;year <= fullYear;year++){
         yearArray.push(year)
        }
    this.defaultYear = yearArray;
    
  }

  findDefaultDepositPeriod(fullYear){
    this.userService.findDefaultDepositPeriod(this.group_id,fullYear).subscribe(
      response =>{
        this.periods = response;
       const periodId = JSON.stringify(response[0].id)
       // console.log("vvvvvvvv"+this.periodId)
        this.onChangePeriod(periodId)
      })
  }

  


  onChangePeriod(e){
    const periodId = e;
    this.userService.fetchOtherSource(periodId).subscribe(
      response =>{ 
        this.otherSourceLists = response;
       // console.log(response)
      })

      this.messageService.getMessage().subscribe((data2)=>{
        this.userService.fetchOtherSource(periodId).subscribe(
          response =>{ 
            this.otherSourceLists = response;
            //console.log(response)
          })

      })


  }

  

  openAddAndEdit(data?){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '700px'
    if(data){
      const obj ={
        id: data.id,
        amount: data.amount,
        title: data.title,
        description: data.description,
        group_id: data.group_id,
        period_id: data.period_id,
        date: data.date,
        month: data.month,
        year: data.year,
      }
    this.userService.formOtherSourcePopulate(obj);
    let dialogRef = this.dialog.open(AddEditDialogComponent,dialogconfig );
    }else{
      let dialogRef = this.dialog.open(AddEditDialogComponent,dialogconfig );
    }
    
  }

  openDeleteDialog(data){
     ///console.log(other_source_id)

     Swal.fire({
      title: 'Do you want to delete ?',
      text: JSON.stringify(data.title),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteAction(data.id)
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })


  }

  deleteAction(other_sourc_id){
   this.userService.delete(other_sourc_id).subscribe(response =>{
     this.messageService.setMessage("ffff");
     console.log(response)
   })

  }


  


}
