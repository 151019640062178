import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { SettingService } from '../setting.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { Group } from './group';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2'
import { RolesService } from 'src/app/service/roles.service';


@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  editing = {};
  rows = [];
  temp = [];
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  public groupForm:FormGroup;
  administrator = false
  supervisor = false


  public animal: string = "fffffffff";
  public name: string = "ggggggggggggggggg";


  columns = [
    { prop: 'registration' },
    { name: 'type' },
    { name: 'full_name' },
    { name: 'phone' },
    { name: 'email' },
    { name: 'location' },
    { name: 'id' }
  ];

  public group: Group = {
    firstName: 'Jack',
    lastName:  'Daniels'
  }

  public settings: Settings;
  constructor(private settingService:SettingService,public appSettings:AppSettings,public dialog: MatDialog,private messageService:MessageService,private roleService:RolesService) {
    this.settings = this.appSettings.settings; 
    this.fetch((data) => {
      this.temp = [...data];
      this.rows = data;
      setTimeout(() => { this.loadingIndicator = false; }, 1500);
    });
  
   }


   fetch(data) {
    this.settingService.viewAllGroups().subscribe(
    resp =>{data(resp)},
    error =>{console.log(error)})

    this.messageService.getMessage().subscribe((data2)=>{
      this.settingService.viewAllGroups().subscribe(
        resp =>{data(resp)},
        error =>{console.log(error)})

    })

    this.administrator = this.roleService.administrator()
    this.supervisor = this.roleService.supervisor()

     
  }



 



  ngOnInit() {
     //this.openEditDialog()
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }


  openEditDialog(value): void {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;

    if (value) {
      const objData = {
        id: value.id,
        name: value.name,
        shortname: value.shortname,
        registration: value.registration,
        type: value.type,
        email: value.email,
        tel: value.tel,
        phone: value.phone,
        fax: value.fax,
        box: value.box,
        location: value.location
      };
      this.settingService.populateForm(objData)
    }

    let mimi = value;
    dialogconfig.data = mimi;
    dialogconfig.height= '500px'
    dialogconfig.width= '1000px'

    let dialogRef = this.dialog.open(GroupDetailDialog,dialogconfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     
    });
  }


  openDialogLock(data): void{
   console.log(data)
  }


  openDialogInfo(data){
    console.log(data)
  }




}




@Component({
  selector: 'group-edit-dialog',
  templateUrl: 'group-edit-dialog.html',
  styleUrls: ['./group-list.component.scss']

})
export class GroupDetailDialog { //
  public groupForm:FormGroup;
  public types = [
    { id: 1, name: 'Kikoba' },
    { id: 2, name: 'Group' },
    { id: 3, name: 'Microfinance' }
  ];
  successMessage = "";
  successMessageStatus = false
 
  constructor(
    public dialogRef: MatDialogRef<GroupDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder,private settingService: SettingService,public dialog: MatDialog,private messageService:MessageService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.groupForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'registration': [''],
      'shortname': ['', Validators.required],
      'logo': ['', Validators.required],
      'type': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'phone': ['', Validators.required],
      'tel': ['', Validators.required],
      'fax': ['', Validators.required],
      'box': ['', Validators.required],
      'location': ['', Validators.required],
    });

 }


 public onSubmit():void {
   
  if(this.groupForm){
    let data = this.settingService.groupForm.value;
    let groupId =data.id;
    this.settingService.saveEditedGroup(groupId,data).subscribe(
      resp =>{
        this.successMessage = "Information saved";
        this.successMessageStatus = true;
        this.messageService.setMessage("mimi")
        this.onNoClick()
        
         //dialogy  ErrorMessageDialog
        //  let dialogRef = this.dialog.open(successMessageDialog,{
        //    height:'400px',
        //    width:'800px'
        //  });

        Swal.fire({
          icon: 'success',
          title: 'Data updated',
          showConfirmButton: false,
          timer: 2000
        })
           
  
      },  
        error =>{
        console.log(error)
         this.successMessage = "Error While Saving data"
         this.successMessageStatus=true
        })
      
  }else{
    Swal.fire({
      icon: 'error',
      title: 'data not saved',
      showConfirmButton: false,
      timer: 2000
    })

  }}




}
export function emailValidator(control: FormControl): {[key: string]: any} {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
  if (control.value && !emailRegexp.test(control.value)) {
      return {invalidEmail: true};
  }
}



///https://stackblitz.com/edit/angular-reactive-edit-form?file=src%2Fapp%2Fperson-edit.component.ts