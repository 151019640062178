import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService } from '../loan.service';
import Swal from 'sweetalert2';
import { PaymentDialogComponent } from './modal/payment-dialog.component';

@Component({
  selector: 'app-loan-payment',
  templateUrl: './loan-payment.component.html',
  styleUrls: ['./loan-payment.component.scss']
})
export class LoanPaymentComponent implements OnInit {

  public loansList = null
  temp = null
  p: number = 1;
  itemsPerPage =10
 
  constructor(
    public appSettings:AppSettings,
    private authService:AuthenticationService,
    public dialog: MatDialog,
    private messageService:MessageService,
    private loanService:LoanService
  ) { }

  ngOnInit() {

    this.fetchLoanFroApproved()
  }
//fetch-loan-for-approved
  fetchLoanFroApproved(){
    this.loanService.fetchLoanApproved().subscribe(response =>{
      this.temp = [...response]; /////hapa
     this.loansList = response;
     console.log(response)
    this.loanService.populateLoan(this.sumLaon(response))
    this.loanService.populateLoanProfit(this.expectedProfit(response))
    })

    this.messageService.getMessage().subscribe(data =>{

      this.loanService.fetchLoanApproved().subscribe(response =>{
        this.loansList = response;
        console.log(response)
       this.loanService.populateLoan(this.sumLaon(response))
       this.loanService.populateLoanProfit(this.expectedProfit(response))
       })

    })


  }

  sumLaon(data){
    let sum = 0;
    for(let i =0;i< data.length;i++){
      sum = sum + JSON.parse(data[i].loan_amount)
    }
    return sum;
  }

  expectedProfit(data){
    let sum = 0;
    for(let i =0;i< data.length;i++){
      let loan_amount = JSON.parse((data[i].loan_amount))
      let rate = JSON.parse((data[i].rate))
      let profit = (loan_amount*rate)/100
      sum = sum + profit
    }
    return sum;

  }

  payment(data){


    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '1000px'
    dialogconfig.data = data
    this.loanService.populateMemberName(data)
    
   // this.loanService.populateLoanList(this.userFetchPayment(data.id))
      this.loanService.paymentList(data.id).subscribe(response =>{
        this.loanService.populateLoanList(response)
      })
      this.messageService.getMessage().subscribe(response =>{
        this.loanService.paymentList(data.id).subscribe(response =>{
          this.loanService.populateLoanList(response)
        })
      })

    let dialogRef = this.dialog.open(PaymentDialogComponent,dialogconfig );
   
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      return d.fname.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.loansList = temp;
    //this.table.offset = 0;
    //console.log("VVVVVVV "+temp)
    //https://www.freakyjolly.com/angular-4-5-typescript-create-filter-list-with-check-boxes-to-select-from-list/
  }


  

  


}
