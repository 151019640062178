import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsComponent } from './sms.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule,MatInputModule,MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HttpClientModule } from '@angular/common/http';
import { SmsBalanceComponent } from './sms-balance/sms-balance.component';
import { SendTOOtherDialogComponent }from './other-number-model/send-to-other-dialog.component'


export const routes = [
  {path:'sms',component:SmsComponent,pathMatch: 'full'},
  {path:'balance',component:SmsBalanceComponent,pathMatch: 'full'},
]


@NgModule({
  declarations: [SmsComponent, SmsBalanceComponent,SendTOOtherDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule
  ],
  entryComponents:[
    SendTOOtherDialogComponent
  ]
})
export class SmsModule { }
