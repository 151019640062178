import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserListService } from './user-list.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { CreateEditUserDialogComponent } from './create-edit-user-dialog.component';
import { enableAndDisableUserDialog } from './enable-and-disable-user-dialog'
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { AddRemoveRolesDialog } from './add-remove-roles-dialog'
import { RolesService } from 'src/app/service/roles.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  public displayedColumns = ['position', 'name', 'weight', 'symbol'];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  editing = {};
  rows = [];
  temp = [];
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [
    { prop: 'fname' },
    { name: 'lname' },
    { name: 'sname' },
    { name: 'groups' },
    { name: 'mobile' },
    { name: 'email' },
    { name: 'enabled'},
  ];
  filter:string = ''
  public animal: string;
  public name: string;
  public settings: Settings;
  public dialogConfig:any
  administrator = false
  supervisor = false


  constructor(public appSettings:AppSettings,private authService: AuthenticationService,private userlist:UserListService,public dialog: MatDialog,private messageService:MessageService,private roleService: RolesService) {
    this.settings = this.appSettings.settings; 
    this.fetch((data) => {
      this.temp = [...data];
      this.rows = data;
      setTimeout(() => { this.loadingIndicator = false; }, 1500);
    });
  
  }

  fetch(data) {
      let roleCurrentUser = this.authService.currentUserRole();
      if(roleCurrentUser == "Administrator"){;
        this.filter = "Administrator";
      }else{
        let currentUserGroupId = this.authService.currentUserGroup();
        this.filter = currentUserGroupId
      }

    this.userlist.fetchUsers(this.filter).subscribe(
      data2 =>{
        data(data2)
       console.log(data2)
    })

     
    this.messageService.getMessage().subscribe((data2)=>{
        this.userlist.fetchUsers(this.filter).subscribe(
          data2 =>{
            data(data2)
        })

      })
    this.administrator = this.roleService.administrator()
    this.supervisor = this.roleService.supervisor()
    
  }


  enableAndDisableUserDialog(data): void{
    let userID = data.id
    let userStatus = data.enabled
    if(userStatus == true){
      var action = false;
      var sms = "Do you want to diactivate User ?"
      var name = data.fname+" "+data.mname+" "+data.sname;
    }else{
      var action = true;
      var sms = "Do you want to activate User ?"
      var name = data.fname+" "+data.mname+" "+data.sname;
    }

    const dataCombined = {
       id:userID,
       action:action,
       sms:sms,
       name:name
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.data = dataCombined;
    dialogconfig.height= '450px'
    dialogconfig.width= '700px'
    let dialogRef = this.dialog.open(enableAndDisableUserDialog,dialogconfig );
  }

  openDialogRole(data): void{
    let userID = data.id
    var name = data.fname+". "+data.mname+". "+data.sname;
    const dataCombined = {
      id:userID,
      name:name
    }
    const dataCombined2 = {
      id:userID,
      roleId:""
    }
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.data = dataCombined;
    dialogconfig.height= 'auto'
    dialogconfig.width= '700px'
    let dialogRef = this.dialog.open(AddRemoveRolesDialog,dialogconfig );

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      return d.fname.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
    //console.log("VVVVVVV "+temp)
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  

  ngOnInit() {
  }


  openDialogInfo(data): void {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.data = data;
    dialogconfig.height= '600px'
    dialogconfig.width= '1000px'

  // console.log(data)
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog,dialogconfig );

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  registerorEditUserDialog(data?):void{
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    //dialogconfig.data = data;
    dialogconfig.height= '450px'
    dialogconfig.width= '800px'
   // console.log(data.id_type)
    if(data){
     // console.log("data")
      const objData = {
        id: data.id,
        fname: data.fname,
        mname: data.mname,
        sname: data.sname,
        id_type: data.id_type,
        id_no: data.id_no,
        mobile: data.mobile,
        email: data.email,
        enabled: data.enabled,
        //roles: data.roles,
        group_id:data.groups.id
        
      };
     // dialogconfig.data = data;
     this.userlist.populateForm(objData);
     let dialogRef = this.dialog.open(CreateEditUserDialogComponent,dialogconfig );
    }else{
      //console.log("ot data")
      let dialogRef = this.dialog.open(CreateEditUserDialogComponent,dialogconfig );
    }
   

  }



}
//https://www.primefaces.org/primeng/showcase/#/table
//https://www.primefaces.org/primeng/showcase/#/table

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',

})
export class DialogOverviewExampleDialog { //

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}