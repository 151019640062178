import {throwError as observableThrowError, throwError,  Observable ,  BehaviorSubject } from 'rxjs';
import {take, filter, catchError, switchMap,mergeMap, finalize,flatMap} from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor,HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';


///,private authService: AuthenticationService
@Injectable({
  providedIn: 'root'
})
export class BasicAuthHtppInterceptorService implements HttpInterceptor {

  constructor(private httpClient:HttpClient, private authService:AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.accessToken()}`
      }
    });
    return next.handle(request);
  }
 
}
