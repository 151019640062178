import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map,catchError, mapTo, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Url = environment.baseUrl;
  // clientUserName = environment.clientUsername;
  // clientPassWord = environment.clientPassword;
  constructor(private httpClient:HttpClient) { }

  authenticate(userCredental){
    //console.log(userCredental)
    //const body = `email=${username}&password=${password}`;

   //console.log(body)
    return this.httpClient.post<any>(`/financial/api/v1/auth/login`,userCredental).pipe(
      map(
        userToken => {
         
          if(userToken.message != "Invalid Username or Password"){

            localStorage.setItem('access_token',userToken.access_token);
            localStorage.setItem('user',JSON.stringify(userToken.user));
            localStorage.setItem('group',JSON.stringify(userToken.group));
            localStorage.setItem('roles',JSON.stringify(userToken.roles));
            localStorage.setItem('menus',JSON.stringify(userToken.menus));
            return userToken;
          }else{
            return userToken;
          }
            
        }
      ));
      
  
  }

  isUserLoggedIn() {
    let  getAuthToken = this.accessToken()
    return !(getAuthToken === null)
  }

  

  logOut() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
    localStorage.removeItem('group')
    localStorage.removeItem('roles')
    localStorage.removeItem('selected_role')
    localStorage.removeItem('selected_privileges')
  }

 // get the stored token
  getAuthToken(){ 
    return sessionStorage.getItem('access_token')
  }

  getRefreshToken(){
    return localStorage.getItem('refresh_token')
  }


  accessToken(){ 
    return localStorage.getItem('access_token')
  }

  userinfo(){ 
    return localStorage.getItem("user")
  }


  group(){ 
    return localStorage.getItem("group")
  }


  roles(){ 
    return localStorage.getItem("roles")
  }

  getSelectedRole(){
    return localStorage.getItem("selected_role");
  }


  getUserRoleById(roleId){
  return this.httpClient.get<any>(`/financial/api/v1/role/${roleId}`)
}

  getUserprivilegeseById(roleId){
    return this.httpClient.get<any>(`/financial/api/v1/privileges/${roleId}`)   
  }

  currentUserRole(){
    let roleCurrentUser = JSON.parse(this.getSelectedRole())
    if(roleCurrentUser[0].name){
      return roleCurrentUser[0].name;
    }else{
      this.logOut()
    }
   
  }

  currentUserGroup(){
    let currentUserGroup = JSON.parse(this.group())
    if(currentUserGroup[0].id){
      return currentUserGroup[0].id;
    }else{
      this.logOut();
    }
  }

  isRoleEligible(accessRole) {
    let selectedRole = this.getSelectedRole()
    let roleParsed = JSON.parse(selectedRole)
    let role = roleParsed[0].name
    if(accessRole == role) {
      return true
    }else{
      return false
    }

  }


}
