import { Injectable } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }

  groupForm: FormGroup = new FormGroup({
    id        : new FormControl(''),
    name      : new FormControl('', [Validators.required]),
    registration : new FormControl('', [Validators.required]),
    shortname   : new FormControl('', [Validators.required]),
    type   : new FormControl('', [Validators.required]),
    email   : new FormControl('', [Validators.required]),
    phone   : new FormControl('', [Validators.required]),
    tel   : new FormControl('', [Validators.required]),
    fax   : new FormControl('', [Validators.required]),
    box   : new FormControl('', [Validators.required]),
    location   : new FormControl('', [Validators.required]),
  });

  policyForm: FormGroup = new FormGroup({
    id        : new FormControl(''),
    group_id      : new FormControl('', [Validators.required]),
    member_period_loan : new FormControl('', [Validators.required]),
    return_loan_period   : new FormControl('', [Validators.required]),
    deposit_period   : new FormControl('', [Validators.required]),
    rate   : new FormControl('', [Validators.required]),
    max_loan_amount   : new FormControl('', [Validators.required]),
    min_deposit_amount   : new FormControl('', [Validators.required]),
  });

  reportForm:FormGroup = new FormGroup({

    id        : new FormControl(''),
    name      : new FormControl('', [Validators.required]),
    url : new FormControl('', [Validators.required]),
    authority   : new FormControl('', [Validators.required]),
    description   : new FormControl('', [Validators.required])

  })

  


  
  saveCreatedGroup(groupInfo){
    return this.http.post<any>('/financial/api/v1/save-new-group',groupInfo);
  }

  viewAllGroups(){
    return this.http.get<any>('/financial/api/v1/all-groups')
  }

  saveEditedGroup(groupId,groupInfo){
    console.log("Group-info"+groupInfo)
    return this.http.post<any>(`/financial/api/v1/save-edit-group/${groupId}`,groupInfo);
  }

  populateForm(data) {
    console.log("0000"+JSON.stringify(data))
    this.groupForm.setValue(data);
  }

  fetchGroupType(){
    return this.http.get<any>('/financial/api/v1/period/period-types')
  }

  fetchGroupPolicy(group_id){
    ///api/v1/fetch-group-policy/{id}
    return this.http.get<any>(`/financial/api/v1/fetch-group-policy/${group_id}`)
  }

  popurateGroupPolicyData(data){
  this.policyForm.setValue(data);
  }

  popuratereport(data){
  this.reportForm.setValue(data);
  }

  updateGroupPolicy(data,group_id){
    //console.log("group IDs "+group_policy_id)
    return this.http.post<any>(`/financial/api/v1/update-group-policy/${group_id}`,data);
  }

  ferchAllReports(){
    return this.http.get<any>(`/financial/api/v1/fetch-all-reports`);
  }

  savaReportData(data){
    return this.http.post<any>(`/financial/api/v1/create-reports`,data);
  }

  saveEditReport(data){
    return this.http.put<any>(`/financial/api/v1/save-edit-reports`,data);
  }

  deleteReportSetting(id){
    return this.http.delete<any>(`/financial/api/v1/delete-reports/${id}`);
  }

}

///https://stackoverflow.com/questions/55019343/how-to-generate-a-pdf-using-angular-7