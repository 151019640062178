import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralReportComponent } from './general-report.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule,MatInputModule } from '@angular/material';
import {NgxPaginationModule} from 'ngx-pagination';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule


export const routes = [
  {path:'user-view',component:GeneralReportComponent,pathMatch: 'full'},
]



@NgModule({
  declarations: [GeneralReportComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    NgxPaginationModule,
    PdfViewerModule
  ]
})
export class GeneralReportModule { }
