import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService} from '../../loan.service';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-payment-dialog.component',
    templateUrl: './payment-dialog.component.html',
    styleUrls: ['../loan-payment.component.scss']
})

export class PaymentDialogComponent{

    loaPaymentForm = {}
    p: number = 1;
    itemsPerPage =6


    constructor(
        public dialogRef: MatDialogRef<PaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private loanService:LoanService
        ){}


        cancel(): void {
            this.dialogRef.close();
          }


        saveLoan(loanId,userId){
              let form:any = this.loaPaymentForm
            this.loanService.memberLoanPayment(userId,loanId,form.amount).subscribe(response =>{
               if(response.status == "200"){
                   this.messageService.setMessage("")
                   this.cancel()
                   this.loaPaymentForm = ''
                Swal.fire({
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 2000
                  })
    

               }else{

                Swal.fire({
                    icon: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 2000
                  })

               }

            })
            
        }

        delete(data){
            //console.log(data.loan_id)
            //deletePaidLoan  loan_id, 

            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.value) {
                    this.loanService.deletePaidLoan(data.loan_id,data.id).subscribe(response =>{
                        if(response.status == "200"){
                           this.messageService.setMessage("iii")
                            Swal.fire(
                            'Deleted!',
                            response.message,
                            'success'
                            )

                        }else{

                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                              })

                        }

                    })
                //   Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )
                }
              })


        }



}