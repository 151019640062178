import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  userCurrent = null
  userRole = null
  userEmail = null

  public userImage = '../assets/img/users/profile.png';
  constructor(private authService:AuthenticationService) { }

  ngOnInit() {
    // let userdetail = this.authService.userdataAndRole();
    // let userdetailP = JSON.parse(userdetail)
    //  this.userCurrent = userdetailP.data.userDetails.name;
    //  this.userEmail = userdetailP.data.userDetails.businessCode;
    //  this.userRole = userdetailP.data.userDetails.username;;

    let userdetail = this.authService.userinfo();
     let userdetailP = JSON.parse(userdetail)
     this.userCurrent = userdetailP.fname+" "+userdetailP.sname;
     this.userEmail = userdetailP.email;
     this.userRole = userdetailP.mobile;
  }

}
