import { Component, OnInit } from '@angular/core';
import { LoanService } from '../loan.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { LoanOnBehalfRequestDialogComponent } from './modal/loan-on-behalf-request-dialog.component';
import { from } from 'rxjs';
import { MessageService } from 'src/app/service/message.service';

@Component({
  selector: 'app-loan-instead',
  templateUrl: './loan-instead.component.html',
  styleUrls: ['./loan-instead.component.scss']
})
export class LoanInsteadComponent implements OnInit {
  public memberLioan = null
  p: number = 1;
  itemsPerPage =10

  constructor(
    private loanService:LoanService,
    public dialog: MatDialog,
    private messageService:MessageService
  ) { }

  ngOnInit() {

    
    this.fetchUserWithinGroup()
  }

 

  fetchUserWithinGroup(){
    this.loanService.fetchUserWithinGroup().subscribe(response =>{
     this.memberLioan = response
    })
  }

  openDialog(data){

    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '1000px'
    this.loanService.populateMemberId(data.id)
    let dialogRef = this.dialog.open(LoanOnBehalfRequestDialogComponent,dialogconfig );
    this.loanService.fetchLoansRelatedToMember(data.id).subscribe(response =>{
    this.loanService.populateMemberLoansList(response)
   })

   this.messageService.getMessage().subscribe(resp =>{
      this.loanService.fetchLoansRelatedToMember(data.id).subscribe(response =>{
      this.loanService.populateMemberLoansList(response)
     })

   })
   
   
  }




}
