import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidenavComponent implements OnInit {
   
  userCurrent = null
  userRole = null
  userEmail = null
  role:any

  public userImage= '../assets/img/users/profile.png';
  public menuItems:Array<any>;
  public settings: Settings;
  constructor(public appSettings:AppSettings, public menuService:MenuService,private authService:AuthenticationService){
      this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
    //this.menuFunction()
    // this.menuItems = this.newMenuFunction();
    this.menuItems = this.menuService.getVerticalMenuItems();
     let userdetail = this.authService.userinfo();
     let userdetailP = JSON.parse(userdetail)
     this.userCurrent = userdetailP.fname+" "+userdetailP.sname;
     this.userEmail = userdetailP.email;
     this.userRole = userdetailP.mobile;
     this.getSelectedRoles()  

     ///console.log(this.menuService.getVerticalMenuItems())

     

    
     
  }


  public  newMenuFunction() {
    let array = JSON.parse(localStorage.getItem('menus'))
    var arrayMenu = [];
    array.forEach(element => {
      let ourMeny = 'new Menu('+element.id+',\''+element.title+'\',\''+element.routerLink+'\','+element.href+',\''+element.icon+'\','+element.target+','+element.hasSubMenu+','+element.parentId+'),'
      //console.log(ourMeny)
      arrayMenu.push(ourMeny);
    });
    //console.log(arrayMenu)
    return arrayMenu
  }


  public menuFunction(){
    var arrayMenu = [];
    JSON.parse(localStorage.getItem('menus')).forEach(element2 => {
      var element = JSON.parse(JSON.stringify(element2));
      let ourMeny = 'new Menu('+element.id+',\''+element.title+'\',\''+element.routerLink+'\','+element.href+',\''+element.icon+'\','+element.target+','+element.hasSubMenu+','+element.parentId+'),'
      arrayMenu.push(ourMeny);
        console.log(arrayMenu)
       //new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0)+","
      // new Menu (3, 'Users Management', '/userList/User-list', null, 'group_add', null, false, 2),
      // new Menu (element.id,element.title, element.routerLink, element.href, element.icon, element.target, element.hasSubMenu, element.parentId)
    })
     //console.log(arrayMenu)
  }

  
  public closeSubMenus(){
    let menu = document.getElementById("vertical-menu");
    if(menu){
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if(child){
          if(child.children[0].classList.contains('expanded')){
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  getSelectedRoles(){
    if(this.authService.getSelectedRole()){
      let role1 = this.authService.getSelectedRole()
      let role2 = JSON.parse(role1)
      this.role = role2[0].name;
    }else{
      this.role = "Role"
    }
  }

}
