import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService } from '../loan.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-loan-approve',
  templateUrl: './loan-approve.component.html',
  styleUrls: ['./loan-approve.component.scss']
})
export class LoanApproveComponent implements OnInit {


  public loansList = null
  p: number = 1;
  itemsPerPage =10
  constructor(
    public appSettings:AppSettings,
    private authService:AuthenticationService,
    public dialog: MatDialog,
    private messageService:MessageService,
    private loanService:LoanService
  ) { }

  ngOnInit() {

    this.fetchLoanFroApproved()
  }
//fetch-loan-for-approved
  fetchLoanFroApproved(){
    this.loanService.fetchLoanFroApproved().subscribe(response =>{
    this.loansList = response;
    //console.log(response)
    this.loanService.populateLoan(this.sumLaon(response))
    this.loanService.populateLoanProfit(this.expectedProfit(response))
    })

    this.messageService.getMessage().subscribe(data =>{

      this.loanService.fetchLoanFroApproved().subscribe(response =>{
        this.loansList = response;
        console.log(response)
       this.loanService.populateLoan(this.sumLaon(response))
       this.loanService.populateLoanProfit(this.expectedProfit(response))
       })

    })


  }

  sumLaon(data){
    let sum = 0;
    for(let i =0;i< data.length;i++){
      sum = sum + JSON.parse(data[i].loan_amount)
    }
    return sum;
  }

  expectedProfit(data){
    let sum = 0;
    for(let i =0;i< data.length;i++){
      let loan_amount = JSON.parse((data[i].loan_amount))
      let rate = JSON.parse((data[i].rate))
      let profit = (loan_amount*rate)/100
      sum = sum + profit
    }
    return sum;

  }

  approve(data){
    Swal.fire({
      title: 'Do you want to Approve :: '+data.loan_amount,
      text: "to user "+data.firstname+" "+data.surname,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
      if (result.value) {
        let basicLoan = data.loan_amount
        let rate = data.rate
        //let total = basicLoan + ((basicLoan * rate)/100)
        let loanId = data.id
        this.update(loanId)
        
      }
    })
  }


  delete(data){
    Swal.fire({
      title: 'Do you want to Delete :: '+data.loan_amount,
      text: "to user "+data.firstname+" "+data.surname,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteLoan(data.id)
      }
    })

  }

  deleteLoan(loanId){
    this.loanService.deleteLoan(loanId).subscribe(response =>{
      if(response.status == "200"){
        this.messageService.setMessage("ddd")
        this.fetchLoanFroApproved()// added
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Loan Approved to User',
          showConfirmButton: false,
          timer: 1500
        })


      }else{

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })

      }
    })

  }


  update(loanId){
   this.loanService.update(loanId).subscribe(response =>{
     if(response.status == "200"){

      this.fetchLoanFroApproved()///added
      this.messageService.setMessage("ggg")
      Swal.fire(
        'Approved!',
        'Loan has been approves.',
        'success')
     }else{

      Swal.fire(
        'Not pproved!',
        'Something went wrong.',
        'error')

     }
   })
  }



}
