import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserService } from '../user.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { AmountEditDialogComponent } from './modal/amount-edit-dialog.component';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {
  defaultYear = null
  public selectedYear = null
  public group_id = null
  public periods =  null
  config: any;
  totalNumber: any;
  public displayedColumns = ['position', 'name', 'weight', 'symbol'];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  editing = {};
  rows = [];
  temp = [];
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [
    { prop: 'fname' },
    { name: 'lname' },
    { name: 'sname' },
    { name: 'basic' },
    { name: 'mobile' },
    { name: 'email' },
    { name: 'other'},
  ];
  filter:string = ''
  public name: string;
  public settings: Settings;
  public dialogConfig:any
  userLists = null
  show = false
  p: number = 1;
  periodForm:Object = {}
  itemsPerPage =10
  idOfThePeriod = null

  constructor(
    public appSettings:AppSettings,
    private authService:AuthenticationService,
    public userService :UserService,
    public dialog: MatDialog,
    private messageService:MessageService
    ) { 
      this.settings = this.appSettings.settings; 
    }

  ngOnInit() {

    
   this.group_id = this.authService.currentUserGroup()
    var y = new Date();
    let fullYear = 2100//= y.getFullYear()
    this.findingDefaultYear(fullYear)
    this.selectedYear = y.getFullYear()
    this.findDefaultDepositPeriod(this.selectedYear)
  }

  

  findingDefaultYear(fullYear){
    var y = new Date();
    var yearArray = [];
    for( let year = 2014;year <= fullYear;year++){
         yearArray.push(year)
        }
    this.defaultYear = yearArray;
    //this.selectedYear = y.getFullYear();
  }

  findDefaultDepositPeriod(fullYear){
    this.userService.findDefaultDepositPeriod(this.group_id,fullYear).subscribe(
      response =>{
        this.periods = response;
        //this.onChangePeriod(response.id)
        let periodId = JSON.stringify(response[0].id)
        this.onChangePeriod(periodId)
      })
  }

  onChangePeriod(e){
    let periodId = e;
    this.idOfThePeriod = e;
    
    localStorage.setItem('templalePeriodId',periodId)
    this.userService.fetchDepositedUsers(this.group_id,periodId).subscribe(
      data =>{
           //console.log(data)
          if(data.status == "205")
          {
            this.show = false
            //console.log("dddddddddddd"+data)
          }else{
            this.show = true
            this.userLists =  data;
            console.log("first Time "+periodId)

          }
       
      },
      error =>{this.show = false})

      this.messageService.getMessage().subscribe((data2)=>{
 
        ///get localstorage temp period
        let templalePeriodId = localStorage.getItem("templalePeriodId");
        this.userService.fetchDepositedUsers(this.group_id,templalePeriodId).subscribe(
          data =>{
              // console.log(data)
              if(data.message == "No Users Found")
              {
                this.show = false
              }else{
                this.show = true
                this.userLists =  data;
                console.log("Second Time "+periodId)
              }
          },
           error =>{this.show = false})

      })

  }

  syncUsers(){
     try {

            //let form:any = this.periodForm
           // const periodID =JSON.parse(form.period_id_form)
            const periodID = this.idOfThePeriod;
            console.log(periodID)
            console.log("ttttt")
            this.userService.activateUsersDeposit(this.group_id,periodID).subscribe(
            response =>{

              if(response.message == "No Users Found")
              {
                this.show = false
              }
              else{
                this.show = true
                this.userLists = response

                 this.syncAlert()


              }
            }, error =>{this.show = false})
            //////

            this.messageService.getMessage().subscribe((data2)=>{

              this.userService.activateUsersDeposit(this.group_id,periodID).subscribe(
                response =>{
    
                  if(response.message == "No Users Found")
                  {
                    this.show = false
                  }
                  else{
                    this.show = true
                    this.userLists = response
    
                   //  this.syncAlert()
    
    
                  }
                }, error =>{this.show = false})


            })

       
     } catch (error) {

      Swal.fire({
        icon: 'error',
        title: "Please make sure you select Period",
        showConfirmButton: false,
        timer: 2000
      })
       
     }
    
  }

  openDepositDialog(userData){
    this.userService.formDepositPopulate(userData)
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.data = userData;
    dialogconfig.height= '400px'
    dialogconfig.width= '700px'
    let dialogRef = this.dialog.open(AmountEditDialogComponent,dialogconfig );
    //console.log(userData)

  }


  syncAlert(){
    let timerInterval
    Swal.fire({
      title: 'Synchronizing Users ...',
      html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b: any = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  print(data){
    this.userService.singleUserDeposit().subscribe(response =>{
     //console.log(response)
      let file = new Blob([response], { type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL,"_blank");
    },error =>{

       let str = error.error.text
      // let file = new Blob([str], { type: 'application/pdf' });            
      // var fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

        // const linkSource = 'data:application/pdf;base64,' + str;
        // const downloadLink = document.createElement("a");
        // const fileName = "sample.pdf";

        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

      // let file = new Blob([str], { type: 'application/pdf'});
      // let fileURL = URL.createObjectURL(file);
      // window.open(fileURL,"_blank");

      console.log("vvv"+str)
        
      
    
     })
  }

  ////https://stackoverflow.com/questions/50260391/open-pdf-from-bytes-array-in-angular-5

}
