import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService} from '../../loan.service';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-loan-payment-dialog.component',
    templateUrl: './loan-payment-dialog.component.html',
    styleUrls: ['../loan-history.component.scss']
})

export class LoanPaymentDialogComponent{

    constructor(
        public dialogRef: MatDialogRef<LoanPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private loanService:LoanService
        ){}


        cancel(): void {
            this.dialogRef.close();
          }

}