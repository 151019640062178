import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanComponent } from './loan.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule,MatInputModule } from '@angular/material';
import { LoanHistoryComponent } from './loan-history/loan-history.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { LoanRequestModalComponent } from './loan-history/modal/loan-request-modal.component';
import { LoanPaymentDialogComponent } from './loan-history/modal/loan-payment-dialog.component'
import { from } from 'rxjs';
import { LoanApproveComponent } from './loan-approve/loan-approve.component';
import { LoanInsteadComponent } from './loan-instead/loan-instead.component';
import { LoanPaymentComponent } from './loan-payment/loan-payment.component';
import { LoanOnBehalfRequestDialogComponent } from './loan-instead/modal/loan-on-behalf-request-dialog.component';
import { PaymentDialogComponent } from './loan-payment/modal/payment-dialog.component';



export const routes = [
  {path:'loan',component:LoanComponent,pathMatch: 'full'},
  {path:'request-and-history',component:LoanHistoryComponent,pathMatch: 'full'},
  {path:'loan-approve',component:LoanApproveComponent,pathMatch: 'full'},
  {path:'loan-instead',component:LoanInsteadComponent,pathMatch: 'full'},
  {path:'loan-payment',component:LoanPaymentComponent,pathMatch: 'full'},
]



@NgModule({
  declarations: [
    LoanComponent,
    LoanHistoryComponent,
    LoanRequestModalComponent,
    LoanPaymentDialogComponent,
    LoanApproveComponent,
    LoanInsteadComponent,
    LoanPaymentComponent,
    LoanOnBehalfRequestDialogComponent,
    PaymentDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    NgxPaginationModule
  ],
  entryComponents:[
    LoanRequestModalComponent,LoanPaymentDialogComponent,LoanOnBehalfRequestDialogComponent,PaymentDialogComponent
  ]
})
export class LoanModule { }
