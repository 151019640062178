import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { SettingService } from '../setting.service'
import { CreateAndEditReportComponent } from './model/create-and-edit-report.component'
import { from } from 'rxjs';
import { MessageService } from 'src/app/service/message.service';


@Component({
  selector: 'app-report-setting',
  templateUrl: './report-setting.component.html',
  styleUrls: ['./report-setting.component.scss']
})
export class ReportSettingComponent implements OnInit {

  reportList = null
  noReportFound = false
  p: number = 1;
  itemsPerPage =10

  constructor(
    private authService:AuthenticationService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private settingService: SettingService,
    private messageService:MessageService
  ) { }

  ngOnInit(){

    this.ferchAllReports()
  }

  ferchAllReports(){
    this.settingService.ferchAllReports().subscribe(response =>{
     if(response.status != "401"){
       this.reportList = response

     }else{
      this.noReportFound = true
      Swal.fire(
        'No report found',
        'That thing is still around?',
        'error'
      )
     }
    })

    this.messageService.getMessage().subscribe(data=>{
      this.settingService.ferchAllReports().subscribe(response =>{
        this.reportList = response
        })
    })

  }


  openCreateAndEdit(data?){

    if(data){
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '1000px'
    //dialogconfig.data = data
    this.settingService.popuratereport(data)
    let dialogRef = this.dialog.open(CreateAndEditReportComponent,dialogconfig );
    }else{

      const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= 'auto'
    dialogconfig.width= '1000px'
    //dialogconfig.data = data
    let dialogRef = this.dialog.open(CreateAndEditReportComponent,dialogconfig );

    }
  }

  deleteReportSetting(id){
   
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.delete(id)
      }
    })
  }

  delete(id){
    this.settingService.deleteReportSetting(id).subscribe(response =>{
      if(response.status =="200"){
        this.messageService.setMessage("jjj")
        Swal.fire(
          'Deleted!',
          'Your report has been deleted.',
          'success')

      }else{
        Swal.fire(
          'Not deleted!',
          'Your report not deleted.',
          'error')

      }
    
    })
  }



}
