import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';
import { SettingComponent } from './setting.component'; 
import { GroupCreateComponent } from './group-create/group-create.component';
import { GroupListComponent } from './group-list/group-list.component';
import { MatDialogModule } from '@angular/material';
import { GroupDetailDialog } from './group-list/group-list.component';
import { successMessageDialog } from './message/success-message-dialog'
import { ErrorMessageDialog } from './message/error-message-dialog';
import { GroupPolicyComponent } from './group-policy/group-policy.component';
import { ReportSettingComponent } from './report-setting/report-setting.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CreateAndEditReportComponent } from './report-setting/model/create-and-edit-report.component';






export const routes = [
  {path:'group',component:SettingComponent,pathMatch: 'full'},
  {path:'create-group',component:GroupCreateComponent,pathMatch: 'full'},
  {path:'group_list', component:GroupListComponent},
  {path:'group_policy', component:GroupPolicyComponent},
  {path:'report-Setting', component:ReportSettingComponent},
  
]


@NgModule({
  declarations: [
    SettingComponent,
    GroupCreateComponent,
     GroupListComponent,
     GroupDetailDialog,
     successMessageDialog,
     ErrorMessageDialog,
     GroupPolicyComponent,
     ReportSettingComponent,
     CreateAndEditReportComponent,
     
    
     
    ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    HttpClientModule,
    MatDialogModule,
    NgxPaginationModule
  ],
  entryComponents:[
  GroupDetailDialog,successMessageDialog,ErrorMessageDialog,CreateAndEditReportComponent
  ]
})
export class SettingModule { }
