import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component'
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { DepositComponent } from './deposit/deposit.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AmountEditDialogComponent } from './deposit/modal/amount-edit-dialog.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { OtherIncomeComponent } from './other-income/other-income.component'; 
import { AddEditDialogComponent } from './other-income/modal/add-edit-dialog-component';
import { UserDepositComponent } from './user-deposit/user-deposit.component';



export const routes = [
  { path: 'view', component: UserComponent, pathMatch: 'full' },
  { path: 'deposit',  component:  DepositComponent, pathMatch: 'full'},
  { path: 'other-income',  component:  OtherIncomeComponent, pathMatch: 'full'},
  { path: 'user-deposit',  component:  UserDepositComponent, pathMatch: 'full'}
];


@NgModule({
  declarations: [
    UserComponent,
    DepositComponent,
    AmountEditDialogComponent,
    OtherIncomeComponent,
    AddEditDialogComponent,
    UserDepositComponent,
   
  ],
  entryComponents: [
    AmountEditDialogComponent,
    AddEditDialogComponent
  ],

  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    NgxDatatableModule,
    NgxPaginationModule
  ]
})
export class UserModule { }
