import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserListService } from './user-list.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { CreateEditUserDialogComponent } from './create-edit-user-dialog.component';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2'


@Component({
    selector: 'app-enable-and-disable-user-dialog',
    templateUrl: './enable-and-disable-user-dialog.html',
    styleUrls: ['./user-list.component.scss']
})

export class enableAndDisableUserDialog{

    constructor(  public dialogRef: MatDialogRef<enableAndDisableUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,public appSettings:AppSettings,private authService: AuthenticationService,private userlist:UserListService,public dialog: MatDialog,private messageService:MessageService){}

    userAction(userId,action){
     //console.log(userId)
     this.userlist.activateDeactivateUser(userId,action).subscribe(
        response =>{
            console.log(response)
            this.messageService.setMessage("msg");
            this.onNoClick()

            Swal.fire({
                icon: 'success',
                title: 'Status changed',
                showConfirmButton: false,
                timer: 2000
              })
            this.data = ""
        },
        error =>{console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Status not changed',
                showConfirmButton: false,
                timer: 2000
              })
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
      }
}