import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SettingService } from '../setting.service'
import Swal from 'sweetalert2'
import { RolesService } from 'src/app/service/roles.service';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})

export class GroupCreateComponent implements OnInit {
  public types = [
    { id: 1, name: 'Kikoba' },
    { id: 2, name: 'Group' },
    { id: 3, name: 'Microfinance' }
  ];
  imageUrl: string | ArrayBuffer ="https://bulma.io/images/placeholders/480x480.png";
  fileName: string = "No file selected";
  public groupForm:FormGroup;
  errorMessage = false;
  public name: string;
  successMessage = null;
  successMessageStatus:boolean = false;
  error2Message:string = null;
  errorMessageStatus:boolean = false
  administrator = false
  supervisor = false


  constructor(private formBuilder: FormBuilder,public snackBar: MatSnackBar,public dialog: MatDialog,private settingService: SettingService,private roleService:RolesService) { }

  ngOnInit() {
    this.groupForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'registration': ['', Validators.required],
      'shortname': ['', Validators.required],
      'logo': ['', Validators.required],
      'type': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'phone': ['', Validators.required],
      'tel': ['', Validators.required],
      'fax': ['', Validators.required],
      'box': ['', Validators.required],
      'location': ['', Validators.required],


    });

    
  //   this.errorMessage = true;
  //  this.openSnackBar("Incorrect Login Information","Try again")
    this.administrator = this.roleService.administrator()
    this.supervisor = this.roleService.supervisor()

  }




  public onSubmit(values:Object):void {
    
    this.settingService.saveCreatedGroup(values).subscribe(
      resp =>{
        console.log(resp)
        // this.openDialog()
        Swal.fire({
          icon: 'success',
          title: 'Group registered',
          showConfirmButton: false,
          timer: 2000
        })

        this.successMessage = "Information saved";
        this.successMessageStatus = true;
        this.groupForm.reset();
  
      },  
        error =>{
          console.log(error)
          this.error2Message = "Error While Saving data"
           this.errorMessageStatus=true
            Swal.fire({
              icon: 'error',
              title: 'data not saved',
              showConfirmButton: false,
              timer: 2000
            })
        })
      
      }


openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
    duration: 3000,
  });
}


// openDialog(): void {

//   let dialogRef = this.dialog.open(Dialogv, {
//     height: '400px',
//     width: '600px',
   
//   });

//   dialogRef.afterClosed().subscribe(result => {
//     console.log('The dialog was closed');
   
//   });
// }




}

export function emailValidator(control: FormControl): {[key: string]: any} {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
  if (control.value && !emailRegexp.test(control.value)) {
      return {invalidEmail: true};
  }
}

// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: './dialog-overview-example-dialog.html'

// })
// export class Dialogv {

//   constructor(
//     public dialogRef: MatDialogRef<Dialogv>,
//     @Inject(MAT_DIALOG_DATA) public data: any) { }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }