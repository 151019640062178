import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  SamAmountPaid = null
  totalLoan = null
  totalLoanProfit = null
  memberId = null
  memberLoans = null
  member = null
  loanPaymentList = null
  constructor(private http:HttpClient) { }

  fetchLoanByUsers(){
  return this.http.get<any>(`/financial/api/v1/fetch-loans-by-user`);
  }


  groupPolicy(){
    return this.http.get<any>(`/financial/api/v1/fetch-group-policy/na`);
  }

  loanPayments(loanId){
    return this.http.get<any>(`/financial/api/v1/loans-payments/${loanId}`);
  }

  populate(data){
  this.SamAmountPaid = data
  }

  populateLoan(data){
    this.totalLoan = data

  }

  populateLoanProfit(data){
    this.totalLoanProfit = data
  }


  requestLoan(amount){
    return this.http.get<any>(`/financial/api/v1/create-loans-by-user/${amount}`);
  }

  fetchLoanFroApproved(){
    return this.http.get<any>(`/financial/api/v1/fetch-loan-for-approved/`);
  }

  fetchLoanApproved(){
    return this.http.get<any>(`/financial/api/v1/loan-approved/`);
  }

  deleteLoan(loanId){
    return this.http.delete<any>(`/financial/api/v1/delete-loan/${loanId}`);
  }

  update(loanId){
    return this.http.get<any>(`/financial/api/v1/approve-the-loan/${loanId}`);
  }

  fetchDefaultYear(){
    return this.http.get<any>(`/financial/api/v1/period/fetch-all-year`);
  }

  fetchUserWithinGroup(){
    return this.http.get<any>(`/financial/api/v1/fetch-uses-within-group`);
  }

  fetchLoansRelatedToMember(memberId){
    return this.http.get<any>(`/financial/api/v1/fetch-loan-related-to-member/${memberId}`);
  }

  populateMemberId(memberId){
    this.memberId = memberId
  }
  populateMemberLoansList(memberLoans){
   this.memberLoans = memberLoans
   console.log(memberLoans)
  }
  populateMemberName(member){
    this.member = member
  }

  populateLoanList(loanPaymentList){
    console.log("vvv"+loanPaymentList)
   this.loanPaymentList = loanPaymentList
  }

  loanCreatedByAdmin(loanAmount,periodname,memberId){
   //loan-created-by-admin
   return this.http.get<any>(`/financial/api/v1/loan-created-by-admin/${loanAmount}/${periodname}/${memberId}`);
  }

  memberLoanPayment(useId,loanId,amount){
    return this.http.get<any>(`/financial/api/v1/member-or-user-loan-payment/${useId}/${loanId}/${amount}`);
  }

  paymentList(loanId){
    return this.http.get<any>(`/financial/api/v1/loan-payment-list/${loanId}`);
  }

  deletePaidLoan(loanId,paymentListId){
    return this.http.get<any>(`/financial/api/v1/delete-payment-list/${loanId}/${paymentListId}`);
  }

}
