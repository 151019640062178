import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { SettingService } from '../setting.service'

@Component({
  selector: 'app-group-policy',
  templateUrl: './group-policy.component.html',
  styleUrls: ['./group-policy.component.scss']
})
export class GroupPolicyComponent implements OnInit {

  public policyForm:FormGroup;
  public periodTypes  = null;
  public rates = null;
  public error;
  public group_id;
  data = {}
  constructor(
    private authService:AuthenticationService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private settingService: SettingService
  ) { }

  ngOnInit() {

    this.policyForm = this.formBuilder.group({
      'min_deposit_amount': ['', Validators.required],
      'max_loan_amount': ['', Validators.required],
      'rate': ['', Validators.required],
      'deposit_period': ['', Validators.required],
      'return_loan_period': ['', Validators.required],
      'member_period_loan': ['', Validators.required],
      'group_id': ['', Validators.required],
      'id': ['', Validators.required],
    });

this.fetchGroupType()
this.rate()
this.group_id = this.findgroupId()
this.fetchGroupPolicy()
  }

  fetchGroupType(){
    this.settingService.fetchGroupType().subscribe(
        response =>{this.periodTypes = response },
        error =>{error = this.error}
    )}

  rate(){
    var rateArray = [];
    for(let i =1;i<=100;i++){
      rateArray.push(i);
    }
    this.rates = rateArray;
  }

  findgroupId(){
    return this.authService.currentUserGroup();
  }

  fetchGroupPolicy(){
    this.settingService.fetchGroupPolicy(this.group_id).subscribe(
      response =>{
       // console.log(response[0])
        this.settingService.popurateGroupPolicyData(response[0]);
      }
    )

  }

  onSubmit(){
    
    if(this.settingService.policyForm.valid){
      let data = this.settingService.policyForm.value;
      this.settingService.updateGroupPolicy(data,this.group_id).subscribe(
      response =>{
        if(response.status == "200")
        {
          Swal.fire({
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 2000
          })

        }else{

          Swal.fire({
            icon: 'error',
            title: response.message,
            showConfirmButton: false,
            timer: 2000
          })

        }
        
      },
      error =>{

        Swal.fire({
          icon: 'error',
          title: "Connectivity Error",
          showConfirmButton: false,
          timer: 2000
        })


      })
       //console.log(data)
    }else{
      Swal.fire({
        icon: 'error',
        title: "Some thing went Wrong",
        showConfirmButton: false,
        timer: 2000
      })
    }
  }

}
