import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserService } from '../user.service';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-deposit',
  templateUrl: './user-deposit.component.html',
  styleUrls: ['./user-deposit.component.scss']
})
export class UserDepositComponent implements OnInit {
  public depositList = null
  p: number = 1;
  itemsPerPage =8;
  

  constructor(
    public appSettings:AppSettings,
    private authService:AuthenticationService,
    private userService :UserService,
    public dialog: MatDialog,
    private messageService:MessageService
  ) { }

  ngOnInit() {
    this.fetchPersonalDeposit()
  }

 fetchPersonalDeposit(){
   this.userService.fetchPersonalDeposit().subscribe(response =>{
    this.depositList = response
    this.userService.populateBasicSum(this.sumBasic(response));
    this.userService.populateOtherSum(this.sumOther(response));
   //console.log(JSON.stringify(response))
   })

 }



 sumBasic(data){
    let sum = 0
    const value=data 
    for(let i=0;i<data.length; i++){
      sum = sum + JSON.parse(value[i].basic)
    }
  return sum;
 }

 sumOther(data){
  let sum = 0
  const value=data 
  for(let i=0;i<data.length; i++){
    sum = sum + JSON.parse(value[i].other)
  }
  return sum;

 }

 



}
