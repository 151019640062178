import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  //constructor(private jwtHelperService: JwtHelperService) {}
  constructor() { }
  
      //  var role1 = localStorage.getItem('selected_role')
      //   let role2 = JSON.parse(role1)
      //   let rolename = role2[0].name;
      //   let rolecode = role2[0].code;
      //   let word = role2[0].description;
      //   if(rolename == "Administrator" && rolecode == "ROLE001" && word == "Oversee the System and all Users") {
      //       return admin
      //   } else if(rolename == "Member" && rolecode == "ROLE002" && word == "Normal Member of the certain group") {
      //       return member //superviser
      //   } else if(rolename == "Superviser" && rolecode == "ROLE003" && word == "Oversee specific group Group") {
      //       return superviser
      //   } else if(rolename == "Accountant" && rolecode == "ROLE004" && word == "Group Report and Information") {
      //       return accountant
      //   }else {
      //       return  admin
      //   }

    getSelectedRoled(){
      return  localStorage.getItem('selected_role')
    }

    getRoleName() {
        let role2 = JSON.parse(this.getSelectedRoled())
        return role2[0].name;
    }

    getRoleCode(){
      let role2 = JSON.parse(this.getSelectedRoled())
        return role2[0].code;  
    }
    getRoleWord(){
      let role2 = JSON.parse(this.getSelectedRoled())
        return role2[0].description;  
    }


    administrator() {
      if(this.getRoleName() == "Administrator" && this.getRoleCode() == "ROLE001" && this.getRoleWord() == "Oversee the System and all Users") {
        return true
      } else {
        return false
      }
    }

    supervisor() { 
      if(this.getRoleName() == "Superviser" && this.getRoleCode() == "ROLE003" && this.getRoleWord() == "Oversee specific group Group") {
        return true
      } else {
        return false
      }
    }
    
    accountant() { 
      if(this.getRoleName() == "Accountant" && this.getRoleCode() == "ROLE004" && this.getRoleWord() == "Group Report and Information") {
        return true
      } else {
        return false
      }
    }

    member() { 
      if(this.getRoleName() == "Member" && this.getRoleCode() == "ROLE02" && this.getRoleWord() == "Normal Member of the certain group") {
        return true
      } else {
        return false
      }
    }
  
}
