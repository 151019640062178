import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authentocationService: AuthenticationService,private router: Router,public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.authentocationService.logOut();
    this.router.navigate(['login']);
    this.openSnackBar("successfully logged out","Ok")

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
