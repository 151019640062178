import { Component,  OnInit,ViewChild,Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { LoanService } from '../loan.service';
import { LoanRequestModalComponent } from './modal/loan-request-modal.component';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { MessageService } from 'src/app/service/message.service';
import { LoanPaymentDialogComponent } from './modal/loan-payment-dialog.component';
import Swal from 'sweetalert2'
import { from } from 'rxjs';

@Component({
  selector: 'app-loan-history',
  templateUrl: './loan-history.component.html',
  styleUrls: ['./loan-history.component.scss']
})
export class LoanHistoryComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
   public loansList = null
   public sms = null
   public visible = false
   itemsPerPage =10;
   p: number = 1;

   

  constructor(
    private loanService:LoanService,
    public dialog: MatDialog,
    private messageService :MessageService
    ) { }

  ngOnInit() {

    this.fetchLoanByUsers()
  }

  fetchLoanByUsers(){
    this.loanService.fetchLoanByUsers().subscribe(response =>{
        if(response.status == 401){
            this.sms = response.message;
            this.visible = true;
            
        }else{
          this.loansList = response;
        }
    })

  this.messageService.getMessage().subscribe(response =>{
    this.loanService.fetchLoanByUsers().subscribe(response =>{
      if(response.status == 401){
          this.sms = response.message;
          this.visible = true;
          
      }else{
        this.loansList = response;
      }
  })


  })
    


  }

  openRequestModal(){

    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.height= '400px'
    dialogconfig.width= '700px'
    let dialogRef = this.dialog.open(LoanRequestModalComponent,dialogconfig );

    }

  dialogViewHistory(loanId){
   
    this.loanService.loanPayments(loanId).subscribe(response =>{
      if(response.status == "401"){
        Swal.fire({
          icon: 'error',
          title: "Please make sure you select Period",
          showConfirmButton: false,
          timer: 2000
        })


      }else{

        const dialogconfig = new MatDialogConfig();
        dialogconfig.disableClose = true;
        dialogconfig.autoFocus = true;
        dialogconfig.data = response;
        dialogconfig.height= 'auto';
        dialogconfig.width= '800px'
        this.loanService.populate(this.sumPaidAmount(response[0].loan_payments))
        let dialogRef = this.dialog.open(LoanPaymentDialogComponent,dialogconfig );

      }

    })

  }

  sumPaidAmount(data){

    let sum = 0;
   let value = null
    value=data  
    for(let j=0;j<data.length;j++){  
         sum= sum + JSON.parse(value[j].loan_amount_paid ) 
    } 
    return sum 
  }

  delete(data){
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteUnapproved(data.id)
        this.messageService.setMessage("jj")
      }
    })
   
  }

  deleteUnapproved(loanId){
    this.loanService.deleteLoan(loanId).subscribe(response =>{
      if(response.status == "200"){
       this.messageService.setMessage("jj")
        Swal.fire(
          'Deleted!',
          'Loan has been deleted',
          'success'
        )

      }else{

        Swal.fire(
          'Not deleted!',
          'Loan not deleted.',
          'error'
        )

      }
    })
  }


}
