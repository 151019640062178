import { Component,Inject } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MatDialog, MatDialogRef,MatDialogConfig,MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule} from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { LoanService} from '../../loan.service';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-loan-request-modal.component',
    templateUrl: './loan-request-modal.component.html',
    styleUrls: ['../loan-history.component.scss']
})

export class LoanRequestModalComponent{

    public loanForm:FormGroup;
    editForm = {}
    rate = null
    maxmum_loan = null
    totalLoan: number = 0;
    loadingIndicator: boolean = true;

 constructor(
    public dialogRef: MatDialogRef<LoanRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public dialog: MatDialog,
    private loanService:LoanService
    ){}

    ngOnInit(){
        this.loanForm = this.formBuilder.group({
            'loan_amount': ['', Validators.required],
        });

      this.groupPolicy() /// console.log("Value fro Parent is "+ OtherIncomeComponent.toChildValue())
    }

    close(): void {
        this.dialogRef.close();
    }

    groupPolicy(){
          //groupPolicy
          this.loanService.groupPolicy().subscribe(response =>{
           this.rate = response[0].rate
           this.maxmum_loan = response[0].max_loan_amount
          })

    }

    onLoanChange(amount){
        this.totalLoan = amount;
    }

    onSubmit(data){
            if(data.loan_amount != ""){
             this.loanService.requestLoan(data.loan_amount).subscribe(response =>{
                 if(response.status == "401"){

                    Swal.fire({
                        icon: 'error',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 2000
                      })

                 }else{

                    this.close()
                    Swal.fire({
                        icon: 'success',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 2000
                      })
                  this.messageService.setMessage("gggg")
                 }
             })

            }else{

                Swal.fire({
                    icon: 'error',
                    title: "Please the amount on input box",
                    showConfirmButton: false,
                    timer: 2000
                  })
            }
        
       

    }


    


}
